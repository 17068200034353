import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const solid = defineStyle(({ colorScheme }) => {
  if (colorScheme === 'action') {
    return {
      background: 'rgba(3, 52, 38, 1)',
      fontSize: '13px',
      letterSpacing: '-0.01em',
      lineHeight: 1.5,
      color: 'white',
      fontWeight: 600,
      px: 6,
    };
  }

  if (colorScheme === 'error') {
    return {
      background: 'rgba(239, 88, 0, 1)',
      fontSize: '13px',
      letterSpacing: '-0.01em',
      lineHeight: 1.5,
      color: 'white',
      fontWeight: 600,
      px: 6,
    };
  }

  return {
    fontSize: '13px',
    letterSpacing: '-0.01em',
    lineHeight: 1.5,
    fontWeight: 600,
    px: 6,
  };
});

const outline = defineStyle(({ colorScheme }) => {
  if (colorScheme === 'action') {
    return {
      color: 'action.800',
      borderColor: 'action.800',
    };
  }

  return {};
});

const ghost = defineStyle(({ colorScheme }) => {
  if (colorScheme === 'action') {
    return {
      color: 'grey.1',
    };
  }

  return {};
});

const link = defineStyle(({ colorScheme }) => {
  if (colorScheme === 'action') {
    return {
      color: 'action.900',
      textDecoration: 'underline',
      _hover: {
        textDecoration: 'none',
      },
    };
  }

  return {
    textDecoration: 'underline',
    _hover: {
      textDecoration: 'none',
    },
  };
});

export const buttonTheme = defineStyleConfig({
  variants: { solid, ghost, outline, link },
});

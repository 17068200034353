import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Flex, Text } from '@chakra-ui/react';

import { SearchFilterButton } from 'src/Search/Filter/SearchFilterButton';
import { SearchFilterSort } from 'src/Search/Filter/SearchFilterSort';
import { useDirectoryUser } from 'src/shared/hooks/useDirectory';

export interface SearchFilterProps {
  total?: number;
  isLoading: boolean;
}
export function SearchFilter({ total, isLoading }: SearchFilterProps) {
  const [search, setSearch] = useSearchParams();

  const q = search.get('q') ?? '';
  const expert = search.get('expert') ?? undefined;
  const tags = search.get('tags') ?? undefined;

  const user = useDirectoryUser(expert);

  const clearer = (key: string) => {
    search.delete(key);
    setSearch(search);
  };

  const shortenedQ = q.length > 20 ? `${q.slice(0, 20)}...` : q;

  const renderedFilters: ReactNode[] = [
    <Text
      fontSize="20px"
      lineHeight="24px"
      letterSpacing="-0.02em"
      color="rgba(3, 52, 38, 0.9)"
      key="first"
    >
      <b>{isLoading ? 'Loading' : total}</b>
      <span style={{ marginLeft: '4px', marginRight: '4px' }}>
        saved answer{total === 1 ? '' : 's'}
      </span>
    </Text>,
  ];

  if (user) {
    renderedFilters.push(
      <SearchFilterButton
        onClick={() => clearer('expert')}
        title={user.name}
        imageUrl={user.image}
        pretext="by"
        key="expert"
      />,
    );
  }

  if (tags) {
    if (user) {
      renderedFilters.push(<Box w="100%" key="spacer" />);
    }

    renderedFilters.push(
      <SearchFilterButton
        onClick={() => clearer('tags')}
        title={`#${tags}`}
        pretext="about"
        key="tags"
        pt={user ? 3 : 0}
      />,
    );
  }

  if (q) {
    if (user || tags) {
      renderedFilters.push(<Box w="100%" key="spacer2" />);
    }
    renderedFilters.push(
      <SearchFilterButton
        onClick={() => clearer('q')}
        title={`"${shortenedQ}"`}
        pretext="matching"
        key="q"
        pt={user || tags ? 3 : 0}
      />,
    );
  }

  return (
    <>
      <Flex alignItems="center" mt={8}>
        <Flex alignItems="center" w="100%" flexWrap="wrap">
          {renderedFilters}
        </Flex>
        <SearchFilterSort />
      </Flex>
    </>
  );
}
/* Rectangle 2861 */

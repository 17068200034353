import { useEffect } from 'react';

import { QuestionSearchItem } from '@core/types/types.common';
import { DBQuestion } from '@core/types/types.db';
import { Editor, EditorContent, EditorContentProps } from '@tiptap/react';

import { useSharedEditor } from 'src/shared/components/Editor/useSharedEditor';

export interface AnswerProps extends Pick<EditorContentProps, 'style'> {
  question: DBQuestion | QuestionSearchItem;
  setEditor?: (editor: Editor | null) => void;
  editable?: boolean;
}

export function Answer({ question, setEditor, style, editable }: AnswerProps) {
  const { editor } = useSharedEditor({
    editable,
    content: question?.answer_json
      ? JSON.parse(question?.answer_json)
      : undefined,
  });

  useEffect(() => {
    setEditor?.(editor);
  }, [setEditor, editor]);

  return (
    <>
      <EditorContent
        editor={editor}
        style={{
          minHeight: '80px',
          padding: '12px 16px',
          paddingBottom: '16px',
          ...style,
        }}
      />
    </>
  );
}

import { Flex, Text } from '@chakra-ui/react';
import { QuestionSearchItem } from '@core/types/types.common';
import { DBQuestion } from '@core/types/types.db';
import { timeAgo } from '@core/util/util.timeAgo';

import { Directory } from 'src/shared/components/Directory/Directory';

export function QuestionVerified({
  question,
}: {
  question: QuestionSearchItem | DBQuestion;
}) {
  return (
    <Flex color="#3A3A3A" fontSize="xs" fontWeight="medium" alignItems="center">
      <Text ml={0}>
        Verified by <Directory userId={question.verified_by} />
        {` • ${timeAgo(question.verified_at ?? '')}`}
      </Text>
    </Flex>
  );
}

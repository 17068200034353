import { useDisclosure } from '@chakra-ui/react';
import { QuestionDeleteEndpoint } from '@core/types/types.endpoint.question';

import { ConfirmDialog } from 'src/shared/components/ConfirmDialog/ConfirmDialog';
import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function useDeleter(id: string, callback?: () => void) {
  const confirmer = useDisclosure();
  const broadcast = useBroadcaster();

  const deleter = useSubmitter(async () => {
    const [err, res] = await callApi<QuestionDeleteEndpoint>(
      '/question/delete',
      {
        id,
      },
    );

    if (err || !res) return new Error(err?.error);

    callback?.();

    broadcast({
      event: 'question:delete',
      questionId: res.id,
    });
  });

  const title = `Delete question`;
  const description = `Are you sure you want to delete this question?`;

  return {
    isDeleting: deleter.isSubmitting,
    confirmer: (
      <ConfirmDialog
        onClose={confirmer.onClose}
        isOpen={confirmer.isOpen}
        title={title}
        description={description}
        onSuccess={deleter.submit}
      />
    ),
    onConfirmDelete: confirmer.onOpen,
    forceDelete: deleter.submit,
  };
}

import { MdArrowDropDown, MdOutlineArrowBackIos } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';

import {
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { DEFAULT_LIMIT } from 'src/Search/const/Search.const.DEFAULT_LIMIT';

export interface SearchFooterProps {
  total: number;
  loading?: boolean;
}

export function SearchFooter({ total, loading }: SearchFooterProps) {
  const [search, setSearch] = useSearchParams();
  const page =
    parseInt(search.get('p')?.replace(/[^0-9]*/g, '') || '0', 10) || 1;
  const limit =
    parseInt(search.get('l')?.replace(/[^0-9]*/g, '') || '0', 10) ||
    DEFAULT_LIMIT;

  const maxPage = Math.ceil(total / limit);

  if (!total || loading) {
    return null;
  }

  return (
    <Flex alignItems="center" mt={6}>
      {total > DEFAULT_LIMIT && (
        <>
          <Menu>
            <Tooltip label="Results per page">
              <MenuButton
                flex="none"
                size="sm"
                variant="outline"
                as={Button}
                rightIcon={<Icon as={MdArrowDropDown} />}
              >
                {limit || DEFAULT_LIMIT}
              </MenuButton>
            </Tooltip>
            <MenuList>
              <MenuOptionGroup
                onChange={(e) => {
                  if (!e) {
                    search.delete('l');
                  } else {
                    search.set('l', e as string);
                  }

                  setSearch(search);
                }}
                value={`${limit}`}
                title="Results per page"
                type="radio"
              >
                <MenuItemOption value="">{DEFAULT_LIMIT}</MenuItemOption>
                <MenuItemOption value="25">25</MenuItemOption>
                <MenuItemOption value="50">50</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          <Text ml={4} color="rgba(3, 52, 38, 1)" fontSize="13px">
            Results per page
          </Text>
        </>
      )}

      <Tooltip placement="bottom" label="Current page">
        <Text ml="auto" color="rgba(3, 52, 38, 1)" fontSize="13px">
          Page {page} of {maxPage}
        </Text>
      </Tooltip>
      {maxPage > 1 && page > 1 && (
        <Tooltip placement="left" label="Go to previous page">
          <IconButton
            aria-label="Go to previous page"
            variant="outline"
            size="sm"
            ml={4}
            isDisabled={page <= 1}
            onClick={() => {
              search.set('p', `${Math.max(page - 1, 1)}`);
              setSearch(search);
            }}
            icon={<Icon as={MdOutlineArrowBackIos} />}
          />
        </Tooltip>
      )}
      {maxPage > 1 && (
        <Tooltip placement="right" label="Go to next page">
          <IconButton
            aria-label="Next page"
            ml={3}
            size="sm"
            variant="outline"
            isDisabled={page >= maxPage}
            onClick={() => {
              search.set('p', `${Math.min(page + 1, maxPage)}`);
              setSearch(search);
            }}
            icon={
              <Icon as={MdOutlineArrowBackIos} transform="rotate(180deg)" />
            }
          />
        </Tooltip>
      )}
    </Flex>
  );
}

import { useCallback } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

export function useSingleSearchParam(key: string) {
  const [search, setSearch] = useSearchParams();

  const value = search.get(key) ?? '';

  const onUpdate = useCallback(
    (v?: string, opt?: NavigateOptions) => {
      if (!v) {
        search.delete(key);
      } else {
        search.set(key, v);
      }
      setSearch(search, opt);
    },
    [key, search, setSearch],
  );

  return [value, onUpdate] as [typeof value, typeof onUpdate];
}

import { DBChannel } from '@core/types/types.db';

export function filterChannels(
  channels: DBChannel[],
  options: {
    requireQbInChannel?: boolean;
    excludeDeleted?: boolean;
    privateUserId?: string;
  } = {},
) {
  const { requireQbInChannel, excludeDeleted, privateUserId } = options;

  if (requireQbInChannel) {
    channels = channels.filter(({ is_member }) => !!is_member);
  }

  if (excludeDeleted) {
    channels = channels.filter(
      ({ is_deleted, is_archived }) => !is_deleted && !is_archived,
    );
  }

  if (privateUserId) {
    channels = channels.filter(
      ({ members }) => members?.includes(privateUserId) ?? true,
    );
  }

  return channels;
}

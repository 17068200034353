import { extendTheme } from '@chakra-ui/react';

import { buttonTheme } from 'src/chakra-theme/components/chakra.theme.button';

export const chakraTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#F8FBFA',
      },
    },
  },
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
    mono: 'IBM Plex Mono, monospace',
  },
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  sizes: {
    container: {
      sm: '680px',
      md: '736px',
      lg: '1024px',
      xl: '1280px',
    },
  },
  fontSizes: {
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px',
  },
  colors: {
    ['grey.1']: '#13202D',
    ['grey.2']: '#646464',
    ['grey.3']: '#9F9E9E',
    ['grey.4']: '#E7E9EA',
    ['grey.5']: '#E7E9EA',
    error: {
      50: 'rgba(239, 88, 0, 0.1)',
      100: 'rgba(239, 88, 0, 0.2)',
      200: 'rgba(239, 88, 0, 0.3)',
      300: 'rgba(239, 88, 0, 0.4)',
      400: 'rgba(239, 88, 0, 0.5)',
      500: 'rgba(239, 88, 0, 0.6)',
      600: 'rgba(239, 88, 0, 0.7)',
      700: 'rgba(239, 88, 0, 0.8)',
      800: 'rgba(239, 88, 0, 0.9)',
      900: 'rgba(239, 88, 0, 1)',
    },
    action: {
      50: 'rgba(3, 52, 38, 0.1)',
      100: 'rgba(3, 52, 38, 0.2)',
      200: 'rgba(3, 52, 38, 0.3)',
      300: 'rgba(3, 52, 38, 0.4)',
      400: 'rgba(3, 52, 38, 0.5)',
      500: 'rgba(3, 52, 38, 0.6)',
      600: 'rgba(3, 52, 38, 0.7)',
      700: 'rgba(3, 52, 38, 0.8)',
      800: 'rgba(3, 52, 38, 0.9)',
      900: 'rgba(3, 52, 38, 1)',
    },
  },
  components: { Button: buttonTheme },
});

import { ReactNode } from 'react';

import {
  Box,
  Flex,
  Heading,
  Select,
  SelectProps,
  Text,
} from '@chakra-ui/react';

export interface SettingsSelectProps extends SelectProps {
  title: string;
  description: ReactNode;
  options: [string, string][];
}

export function SettingsSelect({
  title,
  description,
  options,
  ...selectProps
}: SettingsSelectProps) {
  return (
    <Flex alignItems="center" mt={6}>
      <Box>
        <Heading size="xs">{title}</Heading>
        <Text fontSize="xs" color="action.600">
          {description}
        </Text>
      </Box>
      <Select size="sm" maxW="220px" ml="auto" {...selectProps}>
        {options.map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Flex>
  );
}

import { Endpoint, EndpointError } from '@core/types/types.endpoint';

import { QB_API_TOKEN } from 'src/shared/const/const.QB_API_TOKEN';
import { redirectToLogin } from 'src/shared/util/util.redirectToLogin';

const publicEndpoints = ['/question/viewUnauthorized'];

const deviceId =
  localStorage.getItem('deviceId') ||
  Math.random().toString(36).substring(2, 15);
localStorage.setItem('deviceId', deviceId);

export async function callApi<T extends Endpoint = never>(
  path: T['path'],
  body: T['body'],
): Promise<[EndpointError | null, T['response'] | null]> {
  const headersObj = new Headers();
  headersObj.set('Content-Type', 'application/json');
  headersObj.set('X-Device-Id', deviceId);

  if (!path.startsWith('/')) {
    path = `/${path}`;
  }

  if (!publicEndpoints.includes(path)) {
    const token = localStorage.getItem(QB_API_TOKEN);
    if (!token) {
      redirectToLogin('goBackToThisUrl');
      throw new Error('Unauthorized');
    }

    headersObj.set('Authorization', `Bearer ${token}`);
  }

  try {
    const response = await fetch(`/v1/${path.replace(/^\//g, '')}`, {
      method: 'POST',
      headers: headersObj,
      body: body ? JSON.stringify(body) : undefined,
    });

    if (response.status === 401) {
      // If tempToken is expired, and a permanent slack token was there. Try again with slack token...
      redirectToLogin('goBackToThisUrl');
      throw new Error('Unauthorized');
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const result = await response.json();

    return [null, result] as [null, T];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return [{ error: e.message }, null] as [EndpointError, null];
  }
}

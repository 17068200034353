export function isInsideClassName(
  element: HTMLElement | null,
  className: string,
): boolean {
  if (!element) return false;
  if (element.classList.contains(className)) return true;
  return isInsideClassName(element.parentElement, className);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

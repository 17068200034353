import { createContext, useEffect, useMemo, useRef, useState } from 'react';

import { BroadcastEvent } from '@core/types/types.broadcaster';

import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

type BroadcasterApi = {
  subscribe: (callback?: (event: BroadcastEvent) => void) => () => void;
  fireEvent: (event: BroadcastEvent) => void;
};

const BroadcasterContext = createContext<BroadcasterApi | null>(null);

export function useBroadcaster(
  handler?: (event: BroadcastEvent) => void | 'force-rerender',
) {
  const [, buster] = useState(new Date());
  // A hook that allows you to subscribe to events and fire events
  const api = useRequiredContext(BroadcasterContext);

  useEffect(() =>
    api.subscribe((event) => {
      const res = handler?.(event);
      if (res === 'force-rerender') {
        buster(new Date());
      }
    }),
  );

  return api.fireEvent;
}

export function BroadcasterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const subscribers = useRef<Record<string, (event: BroadcastEvent) => void>>(
    {},
  );
  const api = useMemo<BroadcasterApi>(
    () => ({
      subscribe: (callback) => {
        const subId = Math.random().toString(36);

        if (callback) {
          subscribers.current[subId] = callback;

          return () => {
            delete subscribers.current[subId];
          };
        }
        return () => {};
      },
      fireEvent: (event) => {
        Object.values(subscribers.current).forEach((callback) =>
          callback(event),
        );
      },
    }),
    [],
  );

  return (
    <BroadcasterContext.Provider value={api}>
      {children}
    </BroadcasterContext.Provider>
  );
}

// TipTap extensions
// Marks
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSchemaExtensions({ lowlight }: { lowlight?: any }) {
  return [
    Document,
    Paragraph,
    Text,
    BulletList,
    ListItem,
    Image,
    Link,
    HardBreak,
    Heading.configure({
      levels: [1],
    }),
    CodeBlockLowlight.configure({
      lowlight,
    }),
    // Legacy support
    // Enhancv and some others have old data with bold, italic and strike.
    // This turns of the ability to produce these marks, but keeps the ability to render them.
    // This is a temporary solution. We should migrate all the answer_json and remove those marks from the json.
    Bold.extend({
      addInputRules() {
        return [];
      },
      addPasteRules() {
        return [];
      },
      addKeyboardShortcuts() {
        return {};
      },
      addCommands() {
        return {};
      },
    }),
    Italic.extend({
      addInputRules() {
        return [];
      },
      addPasteRules() {
        return [];
      },
      addKeyboardShortcuts() {
        return {};
      },
      addCommands() {
        return {};
      },
    }),
    Strike.extend({
      addInputRules() {
        return [];
      },
      addPasteRules() {
        return [];
      },
      addKeyboardShortcuts() {
        return {};
      },
      addCommands() {
        return {};
      },
    }),
  ];
}

// TipTap
import { useEffect } from 'react';

import { EditorOptions } from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';
import { useEditor } from '@tiptap/react';
import { common, createLowlight } from 'lowlight';

import { getSchemaExtensions } from 'src/shared/components/Editor/getSchemaExtensions';

const lowlight = createLowlight(common);

export function useSharedEditor(editorOptions: Partial<EditorOptions> = {}) {
  const { editorProps, extensions = [], ...remainingOptions } = editorOptions;

  const editor = useEditor({
    editorProps: {
      ...editorProps,
      attributes: {
        class: 'editor',
        ...editorProps?.attributes,
      },
    },
    extensions: [
      ...getSchemaExtensions({ lowlight }),
      Placeholder.configure({
        showOnlyWhenEditable: false,
      }),
      ...extensions,
    ],
    ...remainingOptions,
  });

  const { editable } = editorOptions;

  // Handle editable prop to enable/disable editor
  useEffect(() => {
    if (editor && typeof editable !== 'undefined') {
      if (editor.isEditable !== editable) {
        editor.setOptions({ editable });
        if (editable) {
          editor.commands.focus();
        }
      }
    }
  }, [editor, editable]);

  return {
    editor,
  };
}

import { IconType } from 'react-icons';
import { LuCode2, LuHeading, LuList, LuPilcrow } from 'react-icons/lu';

import { HStack, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { Editor } from '@tiptap/react';

import { capitalizeFirstLetter } from 'src/shared/util/webHelpers';

export interface EditorButtonsProps {
  editor: Editor;
}

export function EditorButtons({ editor }: EditorButtonsProps) {
  const isActive = (type: string) => {
    if (type === 'paragraph') {
      return (
        editor.isActive(type) &&
        !editor.isActive('heading') &&
        !editor.isActive('bulletList') &&
        !editor.isActive('codeBlock')
      );
    }
    return editor.isActive(type);
  };

  const makeOnClick = (type: string) => () => {
    if (type === 'paragraph') {
      return editor.chain().focus().setParagraph().run();
    }
    if (type === 'bulletList' && !isActive('bulletList')) {
      return editor.chain().focus().toggleBulletList().run();
    }
    if (type === 'codeBlock') {
      return editor.chain().focus().setCodeBlock().run();
    }
    if (type === 'heading') {
      return editor.chain().focus().setHeading({ level: 1 }).run();
    }
  };

  const makeAriaLabel = (type: string) => {
    // Turn bulletList to Bullet List
    return capitalizeFirstLetter(type.replace(/([A-Z])/g, ' $1'));
  };

  const renderButton = (type: string, icon: IconType) => (
    <Tooltip
      hasArrow
      placement="bottom"
      openDelay={100}
      offset={[0, 15]}
      label={makeAriaLabel(type)}
    >
      <IconButton
        onClick={makeOnClick(type)}
        icon={<Icon h={5} w={5} as={icon} pointerEvents="none" />}
        aria-label={makeAriaLabel(type)}
        size="sm"
        variant={isActive(type) ? 'solid' : 'ghost'}
        colorScheme={isActive(type) ? 'facebook' : 'black'}
      />
    </Tooltip>
  );

  return (
    <HStack spacing={2} className={'js-hide-unfocused'} mr="auto">
      {renderButton('paragraph', LuPilcrow)}
      {renderButton('heading', LuHeading)}
      {renderButton('bulletList', LuList)}
      {renderButton('codeBlock', LuCode2)}
    </HStack>
  );
}

import { RefObject } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { timeAgo } from '@core/util/util.timeAgo';

import { Answer } from 'src/shared/components/Answer';
import { Directory } from 'src/shared/components/Directory/Directory';
import { QuestionActions } from 'src/shared/components/Question/Actions/QuestionActions';
import { QuestionContext } from 'src/shared/components/Question/QuestionContext';
import { QuestionSorter } from 'src/shared/components/Question/Sorter/QuestionSorter';
import { useDirectoryUser } from 'src/shared/hooks/useDirectory';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

export function QuestionView({
  isExpanded,
  setEditMode,
  buttonRef,
  isCloseable,
}: {
  isExpanded?: boolean;
  setEditMode: (editMode: boolean) => void;
  buttonRef: RefObject<HTMLButtonElement>;
  isCloseable?: boolean;
}) {
  const question = useRequiredContext(QuestionContext);
  const user = useDirectoryUser(question.verified_by);
  const { pathname } = useLocation();
  const isTestPage = pathname.startsWith('/tester');

  return (
    <>
      <Flex alignItems="center" transition="0.25s" px={5}>
        {/* <AccordionIcon color="black" /> */}
        <Avatar
          src={user?.image}
          name={user?.name}
          height="32px"
          width="32px"
        />

        <Flex width="100%" minH="70px" alignItems="center">
          <Text
            fontWeight={500}
            fontSize="15px"
            lineHeight="20px"
            color="#033426"
            letterSpacing="-0.01em"
            pr={2}
            ml={4}
            py={5}
          >
            {question.title}
          </Text>
        </Flex>
        {!isTestPage && (
          <Center ml="auto" pr={1}>
            {isExpanded ? (
              <QuestionActions onEdit={() => setEditMode(true)} />
            ) : (
              <QuestionSorter />
            )}
          </Center>
        )}
        {isTestPage && (
          <Text w="70px" fontSize="11px" fontWeight="600">
            {question.id}
          </Text>
        )}
        <AccordionButton
          as={IconButton}
          ref={buttonRef}
          variant="link"
          flex="none"
          width="32px"
          height="32px"
          size="sm"
          icon={isCloseable ? <Icon as={IoClose} /> : <AccordionIcon />}
        />
      </Flex>

      <AccordionPanel p={0} mt={0}>
        <Divider />
        <Box py={6} px={8}>
          <Answer
            question={question}
            editable={false}
            style={{ padding: 0, minHeight: 'auto' }}
          />
        </Box>
        <Flex
          color="#3A3A3A"
          background="rgba(229, 252, 235, 1)"
          fontWeight="medium"
          alignItems="center"
          px={5}
          py={5}
          borderBottomEndRadius="8px"
          borderBottomStartRadius="8px"
        >
          <Icon as={IoIosCheckmarkCircle} color="#033426" w={6} h={6} />
          <Text
            ml={2}
            color="rgba(3, 52, 38, 1)"
            fontWeight={400}
            fontSize="13px"
            lineHeight="16px"
          >
            Verified by <Directory userId={question.verified_by} />
            {` — ${timeAgo(question.verified_at ?? '')}`}
          </Text>
        </Flex>
      </AccordionPanel>
    </>
  );
}

export function qbTimeAgo(date: Date | string) {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  // Check if today
  if (new Date().toDateString() === date.toDateString()) {
    // return time
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  // Check if yesterday
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (yesterday.toDateString() === date.toDateString()) {
    return 'Yesterday';
  }

  // Check if it's less than this day a week ago
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 6);
  lastWeek.setHours(0, 0, 0, 0);
  if (lastWeek.getTime() < date.getTime()) {
    return date.toLocaleDateString([], {
      weekday: 'long',
    });
  }

  // return "Month 'Year"
  return `${date.toLocaleDateString([], {
    month: 'short',
  })} '${date.getFullYear() - 2000}`;
}

import { IoArrowBack } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { Accordion, Button, Flex, Heading } from '@chakra-ui/react';

import { SettingsChannel } from 'src/Settings/SettingsChannel';
import { useDirectoryChannels } from 'src/shared/hooks/useDirectory';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function SettingsPage() {
  const channels = useDirectoryChannels({
    excludeDeleted: true,
    requireQbInChannel: true,
  });

  const [c] = useSingleSearchParam('c');
  const i = channels.findIndex((ch) => ch.id === c);

  return (
    <>
      <Flex alignItems="center">
        <Button
          size="sm"
          as={Link}
          to="/"
          colorScheme="action"
          variant="outline"
          leftIcon={<IoArrowBack />}
        >
          Back
        </Button>
        <Heading size="sm" ml={3}>
          Settings
        </Heading>
      </Flex>

      <Heading size="xs" mt={10}>
        Channel settings
      </Heading>
      <Accordion allowMultiple defaultIndex={i === -1 ? undefined : [i]}>
        {channels.map((c) => (
          <SettingsChannel key={c.id} channel={c} />
        ))}
      </Accordion>
    </>
  );
}

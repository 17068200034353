import { LuSettings } from 'react-icons/lu';
import { Link, useLocation } from 'react-router-dom';

import { Box, Icon, IconButton, Tooltip } from '@chakra-ui/react';

export function AppSettingsButton() {
  const { pathname } = useLocation();
  const isSettingsPage = pathname.startsWith('/settings');
  if (isSettingsPage) {
    return null;
  }

  return (
    <Tooltip label="Settings" placement="right">
      <Box
        display="inline-block"
        height="48px"
        width="48px"
        position="fixed"
        bottom="80px"
        left="20px"
      >
        <IconButton
          as={Link}
          to="/settings"
          icon={<Icon w="24px" h="24px" as={LuSettings} />}
          aria-label="Settings"
          isRound
          variant="ghost"
          colorScheme="action"
          size="lg"
        />
      </Box>
    </Tooltip>
  );
}

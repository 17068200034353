import {
  Avatar,
  Button,
  Flex,
  FlexProps,
  Text,
  Tooltip,
} from '@chakra-ui/react';

export interface SearchFilterButtonProps extends FlexProps {
  onClick: () => void;
  title: string;
  imageUrl?: string;
  tooltip?: string;
  pretext: string;
}

export function SearchFilterButton({
  onClick,
  title,
  imageUrl,
  tooltip,
  pretext,
  ...flexProps
}: SearchFilterButtonProps) {
  return (
    <>
      <Flex alignItems="center" wrap="nowrap" {...flexProps}>
        <Text
          fontSize="20px"
          lineHeight="24px"
          fontWeight="400"
          letterSpacing="-0.02em"
          color="rgba(3, 52, 38, 0.9)"
        >
          {pretext}
        </Text>
        <Tooltip label={tooltip || 'Remove filter'}>
          <Button
            variant="link"
            fontSize="20px"
            lineHeight="24px"
            fontWeight="400"
            letterSpacing="-0.02em"
            color="rgba(3, 52, 38, 0.9)"
            onClick={onClick}
            _hover={{
              textDecoration: 'line-through',
            }}
          >
            {imageUrl && (
              <Avatar src={imageUrl} height="26px" width="26px" ml={1.5} />
            )}
            <b style={{ marginLeft: '6px', marginRight: '6px' }}>{title}</b>
          </Button>
        </Tooltip>
      </Flex>
    </>
  );
}

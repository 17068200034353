import { ImCheckmark } from 'react-icons/im';

import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Icon,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { QuestionSearchItem } from '@core/types/types.common';

import { Answer } from 'src/shared/components/Answer';
import { QuestionVerified } from 'src/shared/components/Question/Verified/QuestionVerified';
import { useDirectoryUser } from 'src/shared/hooks/useDirectory';

export interface DuplicateQuestionProps {
  question: QuestionSearchItem;
}

export function DuplicateQuestion({ question }: DuplicateQuestionProps) {
  const user = useDirectoryUser(question.verified_by);

  return (
    <Box
      _first={{
        mt: 4,
      }}
      _notFirst={{
        mt: 10,
      }}
    >
      <Flex alignItems="center">
        <Avatar
          src={user?.image}
          name={user?.name}
          height="26px"
          width="26px"
          mr={1}
        >
          <AvatarBadge boxSize="0.75em" bg="purple.500" borderWidth="1px">
            <Icon as={ImCheckmark} w={2} h={2} color="white" />
          </AvatarBadge>
        </Avatar>
        <Text fontWeight="medium" width="100%" pr={2} ml={2}>
          {question.title}
        </Text>
      </Flex>
      <Box pl="38px" pt={1}>
        <Answer
          question={question}
          editable={false}
          style={{ padding: 0, paddingBottom: 0, minHeight: 'auto' }}
        />
        <Spacer h={2} />
        <QuestionVerified question={question} />
      </Box>
    </Box>
  );
}

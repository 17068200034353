export function timeAgo(date: Date | string) {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  let val = date.getTime();
  val = 0 | ((Date.now() - val) / 1000);

  const length = {
    second: 60,
    min: 60,
    hour: 24,
    day: 7,
    week: 4.35,
    month: 12,
    year: 10000,
  };

  for (const unit in length) {
    const num = length[unit as keyof typeof length];
    const result = val % num;
    if (!(val = 0 | (val / num))) {
      if (unit === 'second') {
        return 'just now';
      }
      let unitString = unit;
      if (result !== 1 && unit !== 'min') {
        unitString += 's';
      }
      return `${result} ${unitString} ago`;
    }
  }
}

import { useEffect, useState } from 'react';

import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function useBuster(queryParam: string) {
  const [val, setVal] = useSingleSearchParam(queryParam);
  const [buster, setBuster] = useState(new Date());

  useEffect(() => {
    if (val) {
      setVal();
      setBuster(new Date());
    }
  }, [val, buster, setVal]);

  return buster;
}

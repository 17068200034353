import { IoIosClose } from 'react-icons/io';

import {
  Avatar,
  Box,
  Button,
  Icon,
  PopoverTrigger,
  Tooltip,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import { useDirectoryMe } from 'src/shared/hooks/useDirectory';

export function AppAccountButton({
  disclosure,
}: {
  disclosure: UseDisclosureReturn;
}) {
  const me = useDirectoryMe();
  const { onToggle, isOpen } = disclosure;

  return (
    <Tooltip label={isOpen ? 'Close' : 'Account'} placement="right">
      <Box
        display="inline-block"
        height="48px"
        width="48px"
        position="fixed"
        bottom="20px"
        left="20px"
      >
        <PopoverTrigger>
          <Button
            variant="unstyled"
            height="48px"
            width="48px"
            transition=".25s"
            borderRadius="50%"
            boxShadow="0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16)"
            zIndex={9999999}
            onClick={onToggle}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar
              opacity={isOpen ? 0 : 1}
              pointerEvents="none"
              transition=".25s"
              src={me.image}
              name={me.name}
              height="48px"
              width="48px"
            />

            <Icon
              position={'absolute'}
              left="50%"
              top="50%"
              transform="translate(-50%, -50%)"
              as={IoIosClose}
              h={8}
              w={8}
              opacity={isOpen ? 1 : 0}
              transition=".25s"
            />
          </Button>
        </PopoverTrigger>
      </Box>
    </Tooltip>
  );
}

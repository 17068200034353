import { useEffect } from 'react';
import { TbSearch, TbX } from 'react-icons/tb';

import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

export interface SearchInputProps {
  searching?: boolean;
  onSearch: (q: string) => void;
  searchVal: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const SearchInput = function SearchInput({
  searching,
  onSearch,
  searchVal,
  value,
  onChange,
  placeholder = 'Search for keywords, questions…',
}: SearchInputProps) {
  useEffect(() => {
    if (searchVal !== value) {
      onChange(searchVal);
    }
    // Only run when q changes
  }, [searchVal]);

  const isClearButton = value && value === searchVal;

  return (
    <InputGroup>
      <Input
        size="lg"
        fontSize="16px"
        lineHeight="24px"
        py={7}
        pl={5}
        placeholder={placeholder}
        bg={'rgba(254, 255, 255, 0.20)'}
        border="2px solid rgba(19, 32, 45, 0.20)"
        borderColor={'#13202D33'}
        boxShadow="0px 4px 4px 0px rgba(19, 32, 45, 0.04)"
        _hover={{
          border: '2px solid rgba(19, 32, 45, 0.70)',
        }}
        _focus={{
          bg: 'white',
          border: '2px solid rgba(19, 32, 45, 0.70)',
          boxShadow: '0px 4px 4px 0px rgba(19, 32, 45, 0.04)',
        }}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
          if (!e.target.value && searchVal) {
            onSearch('');
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onSearch(value);
          }
        }}
      />
      <InputRightElement top="9px" right="9px">
        <IconButton
          aria-label={isClearButton ? 'Clear' : 'Search'}
          size="md"
          isDisabled={!value}
          isLoading={searching}
          variant="ghost"
          onClick={() => {
            // Clear button
            if (isClearButton) {
              onSearch('');
            } else {
              onSearch(value);
            }
          }}
          icon={<Icon h={5} w={5} as={isClearButton ? TbX : TbSearch} />}
        />
      </InputRightElement>
    </InputGroup>
  );
};

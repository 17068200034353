import { ReactNode, RefObject } from 'react';

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';

export interface QuestionProps {
  title: string;
  children: ReactNode;
  buttonRef: RefObject<HTMLButtonElement>;
}

export function SettingsCard({ title, children, buttonRef }: QuestionProps) {
  return (
    <AccordionItem border="none" mt={4}>
      {({ isExpanded }) => (
        <Box
          onClick={() => {
            if (isExpanded) return;
            buttonRef.current?.click();
          }}
          cursor={isExpanded ? undefined : 'pointer'}
          py={0}
          p={isExpanded ? 0 : '1px'}
          _hover={{
            background: isExpanded ? undefined : '#F0F8F5',
            border: isExpanded ? undefined : '1px solid rgba(3, 52, 38, 0.5)',
          }}
          border={
            isExpanded
              ? '2px solid rgba(3, 52, 38, 0.9)'
              : '1px solid rgba(3, 52, 38, 0.2)'
          }
          borderRadius="8px"
          background="#ffffff"
        >
          <h2>
            <AccordionButton ref={buttonRef} minH="54px">
              <Text
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={500}
                fontSize="15px"
                lineHeight="20px"
                color="#033426"
                letterSpacing="-0.01em"
              >
                {title}
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>

          <AccordionPanel
            p={4}
            mt={0}
            borderTop="1px solid"
            borderColor="action.200"
          >
            {children}
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
}

import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { qbTimeAgo } from '@core/util/util.qbTimeAgo';
import { timeAgo } from '@core/util/util.timeAgo';

import { QuestionContext } from 'src/shared/components/Question/QuestionContext';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function QuestionSorter() {
  const question = useRequiredContext(QuestionContext);
  const [sorter] = useSingleSearchParam('sorter');
  const [q] = useSingleSearchParam('q');

  let text = '';
  let tooltipLabel = '';

  if (!q) {
    const createdAgo = timeAgo(question.created_at) ?? '';
    const createdDate = new Intl.DateTimeFormat().format(
      new Date(question.created_at),
    );
    const createdShort = qbTimeAgo(question.created_at) ?? '';

    text = createdShort;
    tooltipLabel = `Asked ${createdAgo} (${createdDate})`;
    if (sorter === 'most_viewed') {
      const count = question.view_count;
      text = `${count || 'No'} view${count !== 1 ? 's' : ''}`;
      tooltipLabel = `${count || 'No'} view${count !== 1 ? 's' : ''}`;
    }
  }

  if (!text) return null;

  return (
    <Tooltip label={tooltipLabel} placement="bottom-end">
      <Flex alignItems="center" flex="none" mr={1.5}>
        {text && (
          <Text
            fontSize="13px"
            fontWeight="500"
            letterSpacing="-0.01em"
            as="span"
            color="rgba(3, 52, 38, 0.6)"
          >
            {text}
          </Text>
        )}
      </Flex>
    </Tooltip>
  );
}

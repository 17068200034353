import { isEditor } from '@core/util/util.isEditor';

import {
  useDirectoryMe,
  useDirectoryTeam,
} from 'src/shared/hooks/useDirectory';

export function useIsEditor() {
  const me = useDirectoryMe();
  const team = useDirectoryTeam();

  return isEditor(team, me.id);
}

import { useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { Box, Flex, Heading, Text, Textarea } from '@chakra-ui/react';
import { DBExtractTestCase } from '@core/types/types.db';
import { TesterExtractSaveEndpoint } from '@core/types/types.endpoint.tester';

import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function TesterExtractItem({ item }: { item: DBExtractTestCase }) {
  const [value, setValue] = useState(item.input_text);
  const [question, setQuestion] = useState(item.input_question);
  const [language, setLanguage] = useState(item.text_language);
  const [teamTitle, setTeamTitle] = useState(item.team_title);
  const [teamDescription, setTeamDescription] = useState(item.team_description);

  const saveSubmitter = useSubmitter(async () => {
    await callApi<TesterExtractSaveEndpoint>('/tester/extract/save', {
      textType: item.text_type,
      ts: item.ts,
      input_text: value,
      input_question: question ?? 'Missing input question',
      text_language: language,
      team_title: teamTitle ?? 'SmithCo',
      team_description:
        teamDescription ?? 'SmithCo is a Customer Support Agency.',
    });
  });

  return (
    <Flex>
      <Box w="50%" p={3}>
        {'Team Name: (Leave empty for SmithCo)'}
        <Textarea
          as={ReactTextareaAutosize}
          value={teamTitle}
          onChange={(e) => setTeamTitle(e.target.value)}
          onBlur={saveSubmitter.submit}
          maxRows={1}
        />
        {
          'Team Description: (Leave empty for SmithCo is a Customer Support Agency.)'
        }
        <Textarea
          as={ReactTextareaAutosize}
          value={teamDescription}
          onChange={(e) => setTeamDescription(e.target.value)}
          onBlur={saveSubmitter.submit}
          maxRows={1}
        />
        {'Question: '}
        <Textarea
          as={ReactTextareaAutosize}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onBlur={saveSubmitter.submit}
          maxRows={1}
        />
        {'Conversation: '}
        <Textarea
          as={ReactTextareaAutosize}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={saveSubmitter.submit}
          maxRows={20}
          mb={3}
        />
        {'Language: (Leave empty for English)'}
        <Textarea
          as={ReactTextareaAutosize}
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          onBlur={saveSubmitter.submit}
          maxRows={1}
        />
      </Box>

      <Flex
        w="40%"
        p={3}
        px={8}
        justifyContent={'space-between'}
        alignContent={'center'}
      >
        <Box mt={3} w="45%">
          {Array.isArray(item.result_qna) ? (
            item.result_qna.map((qna, index) => (
              <Box key={index} mt={3}>
                <Heading size="xs">{qna.question}</Heading>
                <Text size="xs">{qna.answer}</Text>
              </Box>
            ))
          ) : (
            <>
              <Heading size="xs">{item.result_qna.question}</Heading>
              <Text size="xs">{item.result_qna.answer}</Text>
            </>
          )}
        </Box>
        <Box mt={3} ml={5} w="45%">
          {Array.isArray(item.status_quo_qna) ? (
            item.status_quo_qna.map((qna, index) => (
              <Box key={index} mt={3}>
                <Heading size="xs">{qna.question}</Heading>
                <Text size="xs">{qna.answer}</Text>
              </Box>
            ))
          ) : (
            <>
              <Heading size="xs">{item.status_quo_qna.question}</Heading>
              <Text size="xs">{item.status_quo_qna.answer}</Text>
            </>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

import { useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import { Box, Button, Flex, Icon, Input } from '@chakra-ui/react';

export interface EmbeddingAddRowProps {
  onAdd: (
    message: string,
    expected: string,
    expectedWantsInfo: string,
    explanation?: string,
    wants_information?: boolean,
  ) => void;
}

export function EmbeddingAddRow({ onAdd }: EmbeddingAddRowProps) {
  const [msg, setMsg] = useState('');
  const [expected, setExpected] = useState('');
  const [expectedWantsInfo, setExpectedWantsInfo] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleAdd = () => {
    if (!msg) return;
    onAdd(msg, expected || 'none', expectedWantsInfo || 'false');
    setMsg('');
    setExpected('');
    setExpectedWantsInfo('');
    inputRef.current?.focus();
  };

  return (
    <Flex alignItems="center" mt={3}>
      <Icon as={FiPlus} w={4} h={4} />
      <Input
        ml={4}
        placeholder="Message"
        variant="flushed"
        w="70%"
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAdd();
          }
        }}
        ref={inputRef}
      />
      <Input
        ml={3}
        w="110px"
        placeholder="Expected Res?"
        variant="flushed"
        flex="none"
        value={expected}
        onChange={(e) => setExpected(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAdd();
          }
        }}
      />
      <Input
        ml={3}
        w="110px"
        placeholder="Expected WantsInfo?"
        variant="flushed"
        flex="none"
        value={expectedWantsInfo}
        onChange={(e) => setExpectedWantsInfo(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAdd();
          }
        }}
      />
      <Box ml={3} w="80px" flex="none">
        <Button
          size="sm"
          onClick={handleAdd}
          colorScheme={msg ? 'messenger' : undefined}
        >
          Add
        </Button>
      </Box>
    </Flex>
  );
}

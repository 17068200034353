import { useCallback, useEffect, useMemo, useState } from 'react';

import { Endpoint, EndpointError } from '@core/types/types.endpoint';

import { useIsMountedRef } from 'src/shared/hooks/shared.hook.useIsMountedRef';
import { callApi } from 'src/shared/util/util.callApi';

export function useApi<T extends Endpoint = never>(
  path: T['path'],
  body: T['body'],
  condition: boolean = true,
) {
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T['response'] | null>(null);
  const [error, setError] = useState<EndpointError | null>(null);

  const bodyString = JSON.stringify(body);
  // Ensure updating when updating body or query
  const cachedBody = useMemo(() => body, [bodyString]);

  const refetch = useCallback(
    async (body?: T['body']) => {
      setLoading(true);
      await callApi<T>(path, body || cachedBody).then(([err, data]) => {
        if (isMountedRef.current) {
          setLoading(false);
          setData(data);
          setError(err);
        }
      });
    },
    [cachedBody, isMountedRef, path],
  );

  useEffect(() => {
    if (!condition) return;
    // Using function to only update if changed
    setLoading(() => true);
    refetch();
  }, [condition, refetch]);

  return {
    error,
    data,
    loading,
    refetch,
    setData,
  };
}

import { useCallback, useEffect, useRef, useState } from 'react';

import { EndpointError } from '@core/types/types.endpoint';

import { useIsMountedRef } from 'src/shared/hooks/shared.hook.useIsMountedRef';

export type Submitter<Args extends unknown[]> = {
  isSubmitting: boolean;
  submitError: Error | EndpointError | null;
  submit: (...args: Args) => Promise<void>;
  setCallback: (
    callback: (...args: Args) => Promise<Error | null | void>,
  ) => void;
};

export function useSubmitter<Args extends unknown[]>(
  callback?: (...args: Args) => Promise<Error | EndpointError | null | void>,
): Submitter<Args> {
  const cbRef = useRef(callback);
  const cbOverwrote = useRef(false);
  const isMountedRef = useIsMountedRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | EndpointError | null>(
    null,
  );

  useEffect(() => {
    if (callback && !cbOverwrote.current) {
      cbRef.current = callback;
    }
  });

  const submit = useCallback(
    async (...args: Args) => {
      if (isSubmitting) {
        return;
      }

      if (submitError) {
        setSubmitError(null);
      }
      setIsSubmitting(true);

      if (!cbRef.current) {
        throw Error(
          'useSubmitter: No callback registered, please register with setCallback',
        );
      }

      const res = await cbRef.current(...args);

      if (isMountedRef.current) {
        if (res) {
          setSubmitError(res);
        }

        setIsSubmitting(false);
      }
    },
    [isSubmitting, submitError, isMountedRef],
  );

  return {
    submit,
    isSubmitting,
    submitError,
    setCallback: (cb) => {
      cbOverwrote.current = true;
      cbRef.current = cb;
    },
  };
}

import { useEffect, useState } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import { QuestionViewUnauthorizedEndpoint } from '@core/types/types.endpoint.question';
import { timeAgo } from '@core/util/util.timeAgo';

import { SearchInput } from 'src/Search/Input/SearchInput';
import { Answer } from 'src/shared/components/Answer';
import { QB_API_TOKEN } from 'src/shared/const/const.QB_API_TOKEN';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';

export function QuestionPageUnauthorized() {
  const { id = '', token = '' } = useParams<{ id: string; token: string }>();
  const [value, onChange] = useState('');

  const { data, loading, error } = useApi<QuestionViewUnauthorizedEndpoint>(
    '/question/viewUnauthorized',
    {
      id,
      token,
    },
  );

  const navigate = useNavigate();
  const onSearch = (q: string) => {
    if (q) {
      const url = `/questions?q=${q}`;
      return navigate(url);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem(QB_API_TOKEN);
    if (token || error) {
      const url = `/q/${id}`;
      return navigate(url);
    }
  }, [error]);

  return (
    <Box maxW="container.md" m="0 auto" height="auto" px={2} pb={24}>
      <Flex py={8} alignItems="center">
        <Text
          fontSize="28px"
          lineHeight="40px"
          fontWeight={600}
          letterSpacing="-0.01em"
          mr="auto"
          color="#033426"
          minHeight="40px"
        >
          {data?.teamName}
        </Text>
      </Flex>
      <SearchInput
        placeholder="Search for other questions…"
        onSearch={onSearch}
        value={value}
        onChange={onChange}
        searchVal=""
      />
      {!data && loading && (
        <Heading size="sm" mt={12}>
          Loading...
        </Heading>
      )}

      {data && (
        <>
          <Box
            border="2px solid rgba(3, 52, 38, 0.9)"
            borderRadius="8px"
            background="#ffffff"
            mt={12}
          >
            <Flex alignItems="center" transition="0.25s" px={5}>
              <Avatar
                src={data.verifiedBy.image}
                name={data.verifiedBy.name}
                height="32px"
                width="32px"
              />

              <Flex width="100%" minH="70px" alignItems="center">
                <Text
                  fontWeight={500}
                  fontSize="15px"
                  lineHeight="20px"
                  color="#033426"
                  letterSpacing="-0.01em"
                  pr={2}
                  ml={4}
                  py={5}
                >
                  {data.question.title}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Box py={6} px={8}>
              <Answer
                question={data.question}
                editable={false}
                style={{ padding: 0, minHeight: 'auto' }}
              />
            </Box>
            <Flex
              color="#3A3A3A"
              background="rgba(229, 252, 235, 1)"
              fontWeight="medium"
              alignItems="center"
              px={5}
              py={5}
              borderBottomEndRadius="8px"
              borderBottomStartRadius="8px"
            >
              <Icon as={IoIosCheckmarkCircle} color="#033426" w={6} h={6} />
              <Text
                ml={2}
                color="rgba(3, 52, 38, 1)"
                fontWeight={400}
                fontSize="13px"
                lineHeight="16px"
              >
                Verified by {data.verifiedBy.name}
                {` — ${timeAgo(data.question.verified_at ?? '')}`}
              </Text>
            </Flex>
          </Box>
          <Center mt={12}>
            <Button as={Link} to="/questions" colorScheme="action">
              Explore {data.total} saved answers
            </Button>
          </Center>
        </>
      )}
    </Box>
  );
}

import { useState } from 'react';

import {
  Accordion,
  AccordionItem,
  Button,
  Center,
  Heading,
} from '@chakra-ui/react';
import { QuestionDuplicateCheckEndpoint } from '@core/types/types.endpoint.question';

import { SearchInput } from 'src/Search/Input/SearchInput';
import { Question } from 'src/shared/components/Question/Question';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function DuplicatePage() {
  const [q, setQ] = useSingleSearchParam('q');
  const [value, onChange] = useState(q);

  const { data, loading, error, setData } =
    useApi<QuestionDuplicateCheckEndpoint>(
      '/question/duplicateCheck',
      {
        query: q,
      },
      !!q,
    );

  // Listen for question updates
  useBroadcaster((e) => {
    if (e.event === 'question:delete' && data) {
      setData(data.filter((q) => e.questionId !== q.id));
    }
    if (e.event === 'question:save' && data) {
      setData(data.map((q) => (q.id === e.question.id ? e.question : q)));
    }
  });

  let heading = '';
  if (data) {
    heading = data.length
      ? `${data.length} Potential duplicate${data.length === 1 ? '' : 's'}`
      : '✅   No potential duplicates...';
  }
  if (error) {
    heading = 'Error checking for duplicates :(';
  }
  if (loading) {
    heading = 'Checking for duplicates...';
  }

  return (
    <>
      <SearchInput
        placeholder="Check for duplicates…"
        onSearch={setQ}
        value={value}
        onChange={onChange}
        searchVal={q}
      />
      {heading && (
        <Heading size="sm" mt={10}>
          {heading}
        </Heading>
      )}
      {!loading && !!data?.length && (
        <>
          <Accordion allowToggle mb={4} mt={4}>
            {data.map((q) => (
              <AccordionItem key={q.id} border="none" mt={1.5} data-id={q.id}>
                {({ isExpanded }) => (
                  <Question question={q} isExpanded={isExpanded} />
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </>
      )}
      {!loading && (
        <Center mt={12}>
          <Button as="a" href="slack://" colorScheme="action">
            Back to Slack
          </Button>
        </Center>
      )}
    </>
  );
}

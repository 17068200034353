import { QB_API_TOKEN } from 'src/shared/const/const.QB_API_TOKEN';
import { QB_TEAM_KEY } from 'src/shared/const/const.QB_TEAM_KEY';

export function handleToken() {
  const { pathname, origin, hash } = window.location;

  const params = new URLSearchParams(location.search);

  const token = params.get('token');

  let didChange = false;
  if (token) {
    localStorage.setItem(QB_API_TOKEN, token);
    params.delete('token');
    didChange = true;
  }

  const team = params.get('team');
  if (team) {
    sessionStorage.setItem(QB_TEAM_KEY, team);
    params.delete('team');
    didChange = true;
  }

  if (didChange) {
    const newP = params.toString();

    history.replaceState(
      history.state,
      '',
      `${origin}${pathname}${newP ? `?${newP}` : ''}${hash}`,
    );
  }
}

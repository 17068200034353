import { useRef, useState } from 'react';

import { Button, HStack } from '@chakra-ui/react';
import { DBChannel } from '@core/types/types.db';
import { SlackChannelSaveSettingsEndpoint } from '@core/types/types.endpoint.slack';
import { channelSettings } from '@core/util/util.channelSettings';

import { SettingsCard } from 'src/Settings/SettingsCard';
import { SettingsExperts } from 'src/Settings/SettingsExperts';
import { SettingsSelect } from 'src/Settings/SettingsSelect';
import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import {
  DirectoryContext,
  useDirectoryTeam,
} from 'src/shared/hooks/useDirectory';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export interface SettingsChannelProps {
  channel: DBChannel;
}

export function SettingsChannel({ channel }: SettingsChannelProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const team = useDirectoryTeam();
  const directory = useRequiredContext(DirectoryContext);
  const broadcast = useBroadcaster();
  const _settings = channelSettings(team, channel.id);

  const [state, setState] = useState(Object.assign({}, _settings));
  const [newExperts, setNewExperts] = useState<string[]>([]);

  // Make sure to use the correct type for the key
  const update = <T extends keyof typeof _settings>(
    key: T,
    value: (typeof _settings)[T],
  ) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const hasChanges =
    JSON.stringify(state) !== JSON.stringify(_settings) || !!newExperts.length;

  const saver = useSubmitter(async () => {
    const [, res] = await callApi<SlackChannelSaveSettingsEndpoint>(
      '/slack/channelSaveSettings',
      {
        settings: state,
        newExpertIds: newExperts,
        channelId: channel.id,
      },
    );
    if (res) {
      team.channel_settings[channel.id] = res.settings;
      directory.users = directory.users.map(
        (user) => res.updatedUsers.find((u) => u.id === user.id) ?? user,
      );

      broadcast({ event: 'directory:save' });
      setNewExperts([]);
    }
  });

  return (
    <SettingsCard title={`# ${channel.name}`} buttonRef={buttonRef}>
      <SettingsExperts
        channelId={channel.id}
        selectedIds={newExperts}
        setSelectedIds={setNewExperts}
      />
      <SettingsSelect
        title="Start flow on"
        description="Run Auto Responder & Smart Capture"
        options={[
          ['messages', 'Every message'],
          ['questions', 'Only questions'],
          // ['manual', 'Manually'],
        ]}
        value={state.case_open || 'messages'}
        onChange={(e) => {
          update(
            'case_open',
            e.target.value as 'messages' | 'questions' | 'manual',
          );
        }}
      />

      <HStack justifyContent="end" pt={6} pb={2}>
        {hasChanges && (
          <Button
            colorScheme="action"
            onClick={saver.submit}
            isLoading={saver.isSubmitting}
          >
            Save
          </Button>
        )}
        <Button
          onClick={() => {
            setState(Object.assign({}, _settings));
            buttonRef.current?.click();
          }}
        >
          Cancel
        </Button>
      </HStack>
    </SettingsCard>
  );
}

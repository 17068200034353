import { useLocation } from 'react-router-dom';

import { Flex, Text } from '@chakra-ui/react';

import { AppTopbarAddQuestion } from 'src/App/Topbar/App.Topbar.AddQuestion';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function Topbar() {
  const team = useDirectoryTeam();
  const { pathname } = useLocation();
  const isSearchPage = pathname.startsWith('/questions');

  return (
    <Flex py={8} alignItems="center">
      <Text
        fontSize="28px"
        lineHeight="40px"
        fontWeight={600}
        letterSpacing="-0.01em"
        mr="auto"
        color="#033426"
      >
        {team?.title}
      </Text>
      {isSearchPage && <AppTopbarAddQuestion />}
    </Flex>
  );
}

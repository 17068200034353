import { useState } from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BillingLinkEndpoint } from '@core/types/types.endpoint.billing';
import { isExpiredTeam } from '@core/util/util.isExpiredTeam';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { useIsEditor } from 'src/shared/hooks/shared.hook.useIsEditor';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function TrialOverlay() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reason, setReason] = useState('');
  const team = useDirectoryTeam();
  const isExpired = isExpiredTeam(team);
  const isEditor = useIsEditor();

  const { data: billingUrl, loading } = useApi<BillingLinkEndpoint>(
    '/billing/link',
    {},
    isOpen || isExpired,
  );
  useBroadcaster((e) => {
    if (e.event === 'trial-expired') {
      onOpen();
      setReason(e.reason || '');
    }
  });

  return (
    <>
      {isExpired && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Trial expired</AlertTitle>
          <AlertDescription>
            Please upgrade to prevent any interruptions
          </AlertDescription>
          {!!billingUrl?.url && (
            <Button
              as="a"
              ml={5}
              colorScheme="action"
              variant="link"
              target="_blank"
              href={billingUrl?.url}
            >
              Go to billing
            </Button>
          )}
        </Alert>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottom="1px solid rgba(3, 52, 38, 0.1)"
            background="rgba(3, 52, 38, 0.03)"
            color="rgba(3, 52, 38, 1)"
            letterSpacing="-0.01em"
            fontWeight={500}
            p={4}
            height="56px"
            fontSize="16px"
            position="relative"
            lineHeight={1.5}
          >
            Trial expired
            <ModalCloseButton top="50%" transform="translateY(-50%)" />
          </ModalHeader>
          <ModalBody pb={30}>{!!reason && <Text>{reason}</Text>}</ModalBody>
          {isEditor && (
            <ModalFooter>
              <Button
                as="a"
                isLoading={loading}
                target="_blank"
                href={billingUrl?.url}
              >
                Go to billing
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

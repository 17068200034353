import { useState } from 'react';

import {
  Accordion,
  AccordionItem,
  Box,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';

import { AddToChannel } from 'src/App/App.AddToChannel';
import { SearchFilter } from 'src/Search/Filter/SearchFilter';
import { SearchFilterExperts } from 'src/Search/Filter/SearchFilterExperts';
import { SearchInput } from 'src/Search/Input/SearchInput';
import { useQuestionFilter } from 'src/Search/Search.useQuestionFilter';
import { SearchFooter } from 'src/Search/SearchFooter';
import { Question } from 'src/shared/components/Question/Question';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function SearchPage() {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { loading, questions, total, aggs, hasFilter } = useQuestionFilter();

  const [q, setQ] = useSingleSearchParam('q');
  const [value, onChange] = useState(q);

  const isEmpty = !loading && !questions?.length;

  const isNotEmpty = !loading && questions && questions.length > 0;

  const index = selectedId
    ? questions?.findIndex((q) => q.id === selectedId)
    : -1;

  const hideTopUI = !hasFilter && isEmpty;

  return (
    <>
      {!hideTopUI && (
        <SearchInput
          searching={!!q && loading}
          searchVal={q}
          value={value}
          onChange={onChange}
          onSearch={setQ}
        />
      )}
      {!hideTopUI && <SearchFilter total={total} isLoading={loading} />}

      {isEmpty && (
        <Box textAlign="center" pt="200px">
          {!hasFilter && <AddToChannel />}
          {hasFilter && (
            <Text
              color="action.400"
              fontWeight={700}
              fontSize="32px"
              lineHeight="35px"
            >
              No results
            </Text>
          )}
        </Box>
      )}

      {isNotEmpty && (
        <>
          <Accordion
            allowToggle
            mb={4}
            mt={8}
            index={index}
            onChange={(index) => {
              if (typeof index === 'number') {
                setSelectedId(questions[index]?.id ?? '');
              }
            }}
          >
            {questions?.map((question) => (
              <AccordionItem
                key={question.id}
                border="none"
                mt={1.5}
                data-id={question.id}
              >
                {({ isExpanded }) => (
                  <>
                    <Question question={question} isExpanded={isExpanded} />
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
          <SearchFooter total={total ?? 0} loading={loading} />
          <SearchFilterExperts experts={aggs?.experts} tags={aggs?.tags} />
        </>
      )}
      {loading && (
        <Stack spacing={2} mt={6}>
          <Skeleton height="52px" borderRadius={8} />
          <Skeleton height="52px" borderRadius={8} />
          <Skeleton height="52px" borderRadius={8} />
          <Skeleton height="52px" w="90%" borderRadius={8} />
        </Stack>
      )}
    </>
  );
}

import { AiFillQuestionCircle } from 'react-icons/ai';

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

export type TestCase = {
  message: string;
  expected: string;
  explanation?: string;
  actual?: string;
  error?: string;
  expectedWantsInfo?: string;
  wants_information?: boolean;
  wantsInfoReasoning?: string;
};

export interface EmbeddingTestCaseProps {
  state: TestCase;
  setState: (state: TestCase) => void;
  isFetching?: boolean;
  onReRun: () => void;
}

export function EmbeddingTestCase({
  state,
  setState,
  onReRun,
  isFetching,
}: EmbeddingTestCaseProps) {
  let color = 'gray.400';
  if (isFetching) {
    color = 'yellow.400';
  }
  if (state.error) {
    color = 'red.400';
  }
  if (
    state.actual &&
    state.expected === state.actual &&
    state.expectedWantsInfo === state.wants_information?.toString()
  ) {
    color = 'green.400';
  } else if (state.actual) {
    color = 'red.400';
  }

  let resLabel = state.actual;
  if (state.error) {
    resLabel = 'error';
  }

  return (
    <Flex alignItems="center" mt={3}>
      <Box
        w="16px"
        h="16px"
        borderRadius="50%"
        bg={color}
        flex="none"
        onClick={() => {
          setState({
            ...state,
            actual: undefined,
          });
          onReRun();
        }}
      />
      <Input
        ml={4}
        variant="flushed"
        w="100%"
        value={state.message}
        onChange={(e) =>
          setState({
            ...state,
            message: e.target.value,
          })
        }
      />
      <Input
        ml={3}
        w="80px"
        flex="none"
        variant="flushed"
        value={state.expected}
        onChange={(e) =>
          setState({
            ...state,
            expected: e.target.value,
          })
        }
      />
      <Input
        ml={3}
        w="80px"
        flex="none"
        variant="flushed"
        value={state.expectedWantsInfo}
        onChange={(e) =>
          setState({
            ...state,
            expectedWantsInfo: e.target.value,
          })
        }
      />
      <Text ml={3} w="80px" flex="none">
        {resLabel}
      </Text>
      <Popover>
        <PopoverTrigger>
          <Box
            ml={3}
            w="80px"
            flex="none"
            textAlign="center"
            cursor="pointer"
            border="1px"
            borderColor="gray.200"
            p={1}
            borderRadius="md"
            _hover={{ bg: 'gray.100' }}
          >
            {state.wants_information?.toString() ?? 'false'}
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Reasoning</PopoverHeader>
          <PopoverBody>
            {state.wantsInfoReasoning || 'No reasoning provided'}
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Popover>
        <PopoverTrigger>
          <IconButton
            icon={<Icon as={AiFillQuestionCircle} h={5} w={5} color="black" />}
            aria-label="Explanation"
            size="sm"
            ml={2}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Explanation</PopoverHeader>
          <PopoverBody>{state.explanation || 'None given'}</PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

import { DBTeam } from '@core/types/types.db';

export function getLoginUrl(authType: 'openid' | 'bot', team?: DBTeam) {
  const { pathname, search, hash } = location;
  const params: Record<string, string> = {};
  if (pathname !== '/login') {
    params.redirectTo = encodeURIComponent(`${pathname}${search}${hash}`);
  }
  if (window.location.host === 'localhost:3000') {
    params.local = '1';
  }

  if (team) {
    params.team = team.id;
  }

  const paramsString = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `/v1/slack/auth/${authType}?${paramsString}`;
}

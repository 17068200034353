export const promiseAsync = <InputType, ReturnType>(
  maxParallels: number,
  list: InputType[],
  iterator: (entry: InputType, i: number) => Promise<ReturnType>,
): Promise<ReturnType[]> => {
  return new Promise((resolve, reject) => {
    const resArr: ReturnType[] = [];

    if (list.length === 0) {
      return resolve(resArr);
    }

    const cache = {
      nextI: 0,
      completedI: 0,
    };

    function doNext() {
      if (cache.nextI < list.length) {
        const myIndex = cache.nextI;

        iterator(list[cache.nextI], cache.nextI)
          .then((r) => {
            resArr[myIndex] = r;
            cache.completedI++;

            if (cache.completedI === list.length) {
              resolve(resArr);
            }

            doNext();
          })
          .catch(reject);

        cache.nextI++;
      }
    }

    // Go through with either chunksize or entire list
    for (let i = 0; i < maxParallels; i++) {
      doNext();
    }

    return resArr;
  });
};

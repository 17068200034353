import { DIRECTORY_KEY } from 'src/shared/const/const.DIRECTORY_KEY';
import { LOGIN_URL_KEY } from 'src/shared/const/const.LOGIN_URL_KEY';
import { QB_API_TOKEN } from 'src/shared/const/const.QB_API_TOKEN';
import { getLoginUrl } from 'src/shared/util/util.getLoginUrl';

export function redirectToLogin(behaviour: 'goBackToThisUrl' | 'goToHome') {
  // Wipe existing tokens and directory
  localStorage.removeItem(DIRECTORY_KEY);
  localStorage.removeItem(QB_API_TOKEN);

  if (behaviour === 'goBackToThisUrl') {
    sessionStorage.setItem(LOGIN_URL_KEY, getLoginUrl('openid'));
  } else {
    sessionStorage.removeItem(LOGIN_URL_KEY);
  }

  window.location.href = '/login';
}

import React, { useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { promiseAsync } from '@core/async/async.promiseAsync';
import {
  TesterExtractAddBatchEndpoint,
  TesterExtractAddEndpoint,
  TesterExtractListEndpoint,
  TesterExtractRunEndpoint,
  TesterExtractSaveEndpoint,
} from '@core/types/types.endpoint.tester';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';
import { TesterExtractItem } from 'src/Tester/Extract/TesterExtractItem';

const textType = 'chat';

export function TesterExtractPage() {
  const { data, refetch, loading } = useApi<TesterExtractListEndpoint>(
    '/tester/extract/list',
    {
      textType,
    },
  );

  const runAll = useSubmitter(async () => {
    if (!data) return;

    await callApi<TesterExtractRunEndpoint>('/tester/extract/run', {
      textType,
      ts: data.map((d) => d.ts),
    });
  });

  const adder = useSubmitter(async () => {
    await callApi<TesterExtractAddEndpoint>('/tester/extract/add', {
      textType,
    });
    refetch();
  });

  const saveAsStatusQuo = useSubmitter(async () => {
    if (!data) return;

    await promiseAsync(3, data, async (item) => {
      await callApi<TesterExtractSaveEndpoint>('/tester/extract/save', {
        textType: item.text_type,
        ts: item.ts,
        status_quo_qna: item.result_qna,
        text_language: item.text_language,
        input_question: item.input_question ?? 'Missing input question',
        team_title: item.team_title ?? 'SmithCo',
        team_description:
          item.team_description ?? 'SmithCo is a Customer Support Agency.',
      });
    });
    refetch();
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [batchText, setBatchText] = useState('');

  const addBatch = useSubmitter(async () => {
    await callApi<TesterExtractAddBatchEndpoint>('/tester/extract/addBatch', {
      textType,
      text: batchText,
    });
    refetch();
    onClose();
  });

  return (
    <Box w="100%" margin="50px auto" maxW="1920px" p={5}>
      <Heading>Extract chat tester</Heading>
      <Flex>
        <Heading size="md" w="51.2%" px={7} display="flex" alignItems="center">
          Chat history
          <Button
            ml={4}
            colorScheme="messenger"
            size="sm"
            onClick={adder.submit}
            isLoading={loading || adder.isSubmitting}
          >
            Add
          </Button>
          <Button ml={4} colorScheme="messenger" size="sm" onClick={onOpen}>
            Add Batch
          </Button>
          <Button
            ml={4}
            colorScheme="messenger"
            size="sm"
            onClick={runAll.submit}
          >
            Run all
          </Button>
        </Heading>
        <Heading size="md" w="20%" p={3} display="flex" alignItems="center">
          Results
          <Button
            ml="3"
            colorScheme="messenger"
            size="sm"
            onClick={saveAsStatusQuo.submit}
            isLoading={saveAsStatusQuo.isSubmitting || loading}
          >
            Save as Status quo
          </Button>
        </Heading>
        <Heading size="md" w="20%" p={3}>
          Status quo
        </Heading>
      </Flex>

      {data?.reverse().map((d) => <TesterExtractItem key={d.ts} item={d} />)}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Batch</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading size="sm" mb={2}>
              Input format:
            </Heading>
            <Box mb={4}>
              &lt;question&gt; Who should I contact to automate question
              answering?&lt;/question&gt;
              <br />
              &lt;thread&gt;
              <br />
              Looping in Alexa, what do you think?
              <br />
              Not sure! let me loop in Alexandra from strategic partnerships.
              She says QuestionBase is the right product, we should hop on a
              call with their sales team!
              <br />
              &lt;/thread&gt;
            </Box>
            <Textarea
              placeholder="Paste the batch text here..."
              value={batchText}
              onChange={(e) => setBatchText(e.target.value)}
              rows={10}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={addBatch.submit}
              isLoading={addBatch.isSubmitting || loading}
            >
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

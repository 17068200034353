import { useEffect, useRef, useState } from 'react';

import { Accordion, AccordionItem, Divider, Heading } from '@chakra-ui/react';
import { QuestionSearchItem } from '@core/types/types.common';
import { DBQuestion } from '@core/types/types.db';
import { QuestionRelatedEndpoint } from '@core/types/types.endpoint.question';

import { Question } from 'src/shared/components/Question/Question';
import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { callApi } from 'src/shared/util/util.callApi';

export interface QuestionRelatedProps {
  question: DBQuestion;
}

export function QuestionRelated({ question }: QuestionRelatedProps) {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [related, setRelated] = useState<QuestionSearchItem[]>([]);
  const index = selectedId ? related.findIndex((q) => q.id === selectedId) : -1;
  const fetchedCache = useRef<Record<string, boolean>>({});

  const fetchRelated = async (query: string) => {
    if (fetchedCache.current[query]) return;
    fetchedCache.current[query] = true;

    const [err, res] = await callApi<QuestionRelatedEndpoint>(
      '/question/related',
      {
        query,
      },
    );
    if (err || !res) return;
    setRelated((curr) => {
      const currIds = [question.id, ...curr.map((q) => q.id)];
      const filtered = res.filter((q) => !currIds.includes(q.id));
      if (!filtered.length) {
        return curr;
      }

      return [...curr, ...filtered.slice(0, 3)];
    });
  };

  useEffect(() => {
    fetchRelated(question.title);
  }, []);

  // Listen for question updates
  useBroadcaster((e) => {
    if (e.event === 'question:delete') {
      setRelated(related.filter((q) => e.questionId !== q.id));
    }
    if (e.event === 'question:save') {
      setRelated(related.map((q) => (q.id === e.question.id ? e.question : q)));
    }
  });

  if (!related.length) return null;

  return (
    <>
      <Divider mt={20} />
      <Heading
        fontSize="13px"
        lineHeight="16px"
        letterSpacing="-0.01em"
        color="action.800"
        fontWeight={500}
        mt={3}
      >
        Related questions
      </Heading>
      <Accordion
        allowToggle
        mb={4}
        mt={4}
        index={index}
        onChange={(index) => {
          if (typeof index === 'number') {
            setSelectedId(related[index]?.id ?? '');
            fetchRelated(related[index].title);
          }
        }}
      >
        {related?.map((q) => (
          <AccordionItem key={q.id} border="none" mt={1.5} data-id={q.id}>
            {({ isExpanded }) => (
              <>
                <Question question={q} isExpanded={isExpanded} />
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
}

import { ReactNode } from 'react';

import { PropsOf, Textarea } from '@chakra-ui/react';
import { Editor, EditorContent } from '@tiptap/react';

import { isInsideClassName } from 'src/shared/util/webHelpers';

export interface AnswerEditorProps {
  editor: Editor | null;
  children?: ReactNode;
}

export function AnswerEditor({
  editor,
  children,
  ...textareaProps
}: AnswerEditorProps & PropsOf<typeof Textarea>) {
  return (
    <Textarea
      className="js-focus-parent"
      variant="unstyled"
      position="relative"
      as="div"
      h="auto"
      minH="auto"
      pb={2}
      onClick={(e) => {
        // Focus when clicking surrounding container.
        if (
          (e.target as HTMLElement).tagName !== 'BUTTON' &&
          !isInsideClassName(e.target as HTMLElement, 'ProseMirror')
        ) {
          editor?.commands.focus();
        }
      }}
      {...textareaProps}
    >
      <EditorContent
        editor={editor}
        style={{
          minHeight: '50px',
        }}
      />
      {children}
    </Textarea>
  );
}

import { ReactNode } from 'react';

import { useDirectoryInit } from 'src/App/App.useDirectoryInit';
import {
  DirectoryContext,
  useDirectoryUser,
  useDirectoryUsers,
} from 'src/shared/hooks/useDirectory';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

export interface DirectoryProps {
  userId?: string;
  userIds?: string[];
  team?: boolean;
  me?: boolean;
  firstName?: boolean;
  fallback?: string;
}

export function DirectoryProvider({ children }: { children: ReactNode }) {
  const directory = useDirectoryInit();

  if (!directory) {
    return <>Loading...</>;
  }

  return (
    <DirectoryContext.Provider value={directory}>
      {children}
    </DirectoryContext.Provider>
  );
}

export function Directory({
  userId,
  userIds = [],
  team,
  me,
  firstName,
  fallback,
}: DirectoryProps) {
  const directory = useRequiredContext(DirectoryContext);
  let user = useDirectoryUser(userId);
  const users = useDirectoryUsers(userIds);

  if (team && directory.team) {
    return <>{directory.team.title}</>;
  }

  if (me) {
    user = directory.me;
  }

  if (users.length) {
    const userString = users
      .map((user, i) => {
        const { name, first_name } = user ?? {
          first_name: 'Unknown',
          name: 'Unknown',
        };

        const r = firstName ? first_name : name;
        let separator = '';
        if (i > 0) {
          if (i === users.length - 1) {
            separator = ' & ';
          } else {
            separator = ', ';
          }
        }
        return `${separator}${r}`;
      })
      .join('');

    return <>{userString}</>;
  }
  if (user) {
    if (me) {
      return <>{'You'}</>;
    }
    if (firstName) {
      return <>{user.first_name}</>;
    }
    return <>{user.name}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }
  return <>Unknown</>;
}

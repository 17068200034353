import { useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { Avatar, Button, Flex, Icon, Text } from '@chakra-ui/react';

import { Directory } from 'src/shared/components/Directory/Directory';
import { useDirectoryUsers } from 'src/shared/hooks/useDirectory';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function SearchFilterExperts({
  experts,
  tags,
}: {
  experts?: Record<string, number>;
  tags?: Record<string, number>;
}) {
  const [userId, setUserId] = useSingleSearchParam('expert');
  const sortedExperts = Object.entries(experts ?? {}).sort((a, b) => {
    return b[1] - a[1];
  });
  const [selTag, setSelTag] = useSingleSearchParam('tags');

  const sortedTags = Object.entries(tags ?? {}).sort((a, b) => {
    return b[1] - a[1];
  });

  const users = useDirectoryUsers(sortedExperts.map(([user]) => user));
  const selUser = users.find((u) => u.id === userId);

  const tagRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const hiddenSize = 94;
  useEffect(() => {
    if (!tagRef.current) return;
    const _hasOverflow = tagRef.current.scrollHeight > hiddenSize;

    if (hasOverflow !== _hasOverflow) {
      setHasOverflow(_hasOverflow);
    }
  });

  return (
    <>
      <Text fontSize="13px" fontWeight={700} color="#033426" mt="100px">
        Tags & Experts
      </Text>

      {/* Hide above 3 lines with a show more button */}
      <Flex
        flexWrap="wrap"
        mt={6}
        maxH={showMore ? undefined : `${hiddenSize}px`}
        overflowY="hidden"
        ref={tagRef}
      >
        {selTag && (
          <Button
            mr={8}
            mb={4}
            variant="link"
            size="sm"
            fontSize="13px"
            lineHeight="16px"
            fontWeight={700}
            borderRadius="20px"
            color="#033426"
            onClick={() => setSelTag()}
            rightIcon={<Icon as={MdClose} />}
          >
            #{selTag}
          </Button>
        )}
        {sortedTags
          .filter(([tag]) => tag !== selTag)
          // Render the tag
          .map(([tag, count]) => {
            return (
              <Button
                mr={6}
                mb={4}
                variant="link"
                size="sm"
                fontSize="13px"
                lineHeight="16px"
                fontWeight={400}
                borderRadius="20px"
                key={tag}
                color="#033426"
                onClick={() => setSelTag(tag)}
              >
                #{tag} ({count})
              </Button>
            );
          })}
      </Flex>

      {hasOverflow && (
        <Button
          variant="link"
          colorScheme="action"
          size="sm"
          onClick={() => setShowMore(!showMore)}
        >
          Show {showMore ? 'less' : 'all'}
        </Button>
      )}

      <Flex flexWrap="wrap" mt={8}>
        {selUser && (
          <Button
            mr={8}
            mb={4}
            variant="link"
            size="sm"
            fontSize="13px"
            lineHeight="16px"
            fontWeight={700}
            borderRadius="20px"
            color="#033426"
            onClick={() => setUserId()}
            rightIcon={<Icon as={MdClose} />}
          >
            <Avatar size="sm" mr={2} src={selUser.image} />
            <span>
              <Directory userId={selUser.id} />
            </span>
          </Button>
        )}
        {!userId &&
          sortedExperts
            .filter(([user]) => user !== userId)
            .map(([user, count], i) => {
              if (!users[i]) return null;

              const image = users[i]?.image;
              return (
                <Button
                  key={user}
                  mr={6}
                  mb={4}
                  variant="link"
                  size="sm"
                  fontSize="13px"
                  lineHeight="16px"
                  fontWeight={400}
                  borderRadius="20px"
                  color="#033426"
                  onClick={() => setUserId(user)}
                >
                  <Avatar size="sm" mr={2} src={image} />
                  <span>
                    <Directory userId={user} /> ({count})
                  </span>
                </Button>
              );
            })}
      </Flex>
    </>
  );
}

import { IntegrationAuthEndpoint } from '@core/types/types.endpoint.integration';
import {
  AutoSyncedSourceTypes,
  CarbonConnect,
  EmbeddingGenerators,
  IntegrationName,
} from 'carbon-connect';

import { callApi } from 'src/shared/util/util.callApi';

export function IntegrationPage() {
  const tokenFetcher = async () => {
    const [err, res] = await callApi<IntegrationAuthEndpoint>(
      '/integration/auth',
      {},
    );

    if (err || !res) {
      throw new Error('Failed to fetch token');
    }

    return res;
  };

  return (
    <CarbonConnect
      orgName="Question Base"
      brandIcon="/assets/logoimage.png"
      embeddingModel={EmbeddingGenerators.COHERE_MULTILINGUAL_V3}
      tokenFetcher={tokenFetcher}
      enabledIntegrations={[
        {
          id: IntegrationName.NOTION,
          chunkSize: 1500,
          overlapSize: 20,
          enableAutoSync: true,
        },
        {
          id: IntegrationName.GOOGLE_DRIVE,
          chunkSize: 1500,
          overlapSize: 20,
          enableAutoSync: true,
          fileSyncConfig: {
            detect_audio_language: true,
            split_rows: true,
            generate_chunks_only: true,
          },
        },
        {
          id: IntegrationName.INTERCOM,
          chunkSize: 1500,
          overlapSize: 20,
          enableAutoSync: true,
          syncFilesOnConnection: true,
          fileSyncConfig: {
            auto_synced_source_types: [
              AutoSyncedSourceTypes.TICKET,
              AutoSyncedSourceTypes.ARTICLE,
            ],
            sync_attachments: true,
          },
        },
      ]}
      onSuccess={(data) => console.log('Data on Success: ', data)}
      onError={(error) => console.log('Data on Error: ', error)}
      chunkSize={1500}
      overlapSize={20}
      open={true}
      alwaysOpen
      entryPoint="INTEGRATION_LIST"
      setPageAsBoundary
    ></CarbonConnect>
  );
}

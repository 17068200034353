import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { isExpiredTeam } from '@core/util/util.isExpiredTeam';

import { AppAccount } from 'src/App/Account/App.Account';
import { AddBot } from 'src/App/App.AddBot';
import { AppSettingsButton } from 'src/App/App.SettingsButton';
import { TrialOverlay } from 'src/App/App.TrialOverlay';
import { Topbar } from 'src/App/Topbar/App.Topbar';
import 'src/shared/css/base.css';
import 'src/shared/css/codeeditor.css';
import 'src/shared/css/editor.css';
import 'src/shared/css/focus-within-hack.css';
import 'src/shared/css/hover-within.css';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export interface LayoutProps {
  children: ReactNode;
}

export function App({ children }: LayoutProps) {
  const team = useDirectoryTeam();
  const isExpired = isExpiredTeam(team);
  const { pathname } = useLocation();
  const shouldBlur = isExpired && pathname.startsWith('/questions');

  if (!team.slack_bot_user_id) {
    return (
      <>
        <AddBot />
        <AppAccount />
      </>
    );
  }

  return (
    <>
      <TrialOverlay />
      <Box
        maxW="container.md"
        m="0 auto"
        height="auto"
        px={2}
        pb={24}
        pointerEvents={shouldBlur ? 'none' : undefined}
        filter={shouldBlur ? 'blur(5px)' : undefined}
        userSelect={shouldBlur ? 'none' : undefined}
      >
        <Topbar />
        {children}
      </Box>
      <AppSettingsButton />
      <AppAccount />
    </>
  );
}

import { useEffect, useMemo, useState } from 'react';

import { Directory } from '@core/types/types.common';
import { DirectoryInitEndpoint } from '@core/types/types.endpoint.directory';

import { DIRECTORY_KEY } from 'src/shared/const/const.DIRECTORY_KEY';
import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { callApi } from 'src/shared/util/util.callApi';

export function useDirectoryInit() {
  const hydratedDir = useMemo(() => {
    const dir = localStorage.getItem(DIRECTORY_KEY);
    if (dir) {
      return JSON.parse(dir) as Directory;
    }
  }, []);

  const [directory, setDirectory] = useState<Directory | undefined>(
    hydratedDir,
  );

  const broadcast = useBroadcaster((e) => {
    if (e.event === 'directory:save') {
      setDirectory(Object.assign({}, e.directory || directory));
      localStorage.setItem(
        DIRECTORY_KEY,
        JSON.stringify(e.directory || directory),
      );
    }
  });

  useEffect(() => {
    // if (!token) return;
    callApi<DirectoryInitEndpoint>('/directory/init', {}).then(([, res]) => {
      if (res) {
        setDirectory(res);
        broadcast({ event: 'directory:save', directory: res });
      }
    });
  }, []);

  return directory;
}

import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_LIMIT } from 'src/Search/const/Search.const.DEFAULT_LIMIT';
import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { useESQuestionList } from 'src/shared/hooks/useESQuestionList';

export function useQuestionFilter() {
  const [search, setSearch] = useSearchParams();
  const prevSearch = useRef(search);
  const q = search.get('q') ?? '';

  const sorter = search.get('sorter') ?? '';
  const expert = search.get('expert') ?? undefined;
  const minScore = search.get('minScore')
    ? parseFloat(search.get('minScore') ?? '')
    : undefined;

  const tags = (search.get('tags') ?? '').split(',').filter((v) => !!v);
  const limit =
    parseInt(search.get('l')?.replace(/[^0-9]*/g, '') || '0', 10) ||
    DEFAULT_LIMIT;
  const page =
    parseInt(search.get('p')?.replace(/[^0-9]*/g, '') || '0', 10) || 1;

  const hasNoFilter = page === 1 && !q && !tags.length && !expert;

  // If these search params have changed, reset the page
  useEffect(() => {
    const busters = ['sorter', 'q', 'l', 'tags', 'expert'];
    const changed = busters.some((key) => {
      return prevSearch.current.get(key) !== search.get(key);
    });
    if (changed && search.get('p')) {
      search.delete('p');
      setSearch(search);
    }
    prevSearch.current = search;
  });

  const { result, loading } = useESQuestionList({
    sorter,
    query: q,
    limit,
    skip: (page - 1) * limit,
    filter: {
      verified_by: expert,
      tags,
    },
    minScore,
  });

  // Listen for question updates
  useBroadcaster((e) => {
    if (e.event === 'question:delete' && result) {
      result.questions = result.questions.filter((q) => e.questionId !== q.id);

      return 'force-rerender';
    }
    if (e.event === 'question:save' && result) {
      result.questions = result.questions.map((q) =>
        q.id === e.question.id ? e.question : q,
      );
      return 'force-rerender';
    }
  });

  return { ...result, loading, hasFilter: !hasNoFilter };
}

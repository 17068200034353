import { MdArrowDropDown } from 'react-icons/md';

import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Tooltip,
} from '@chakra-ui/react';

import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function SearchFilterSort() {
  const [sorter, setSorter] = useSingleSearchParam('sorter');
  const [q] = useSingleSearchParam('q');

  const sorters = [
    ['', 'Newest'],
    ['most_viewed', 'Most viewed'],
  ];

  const activeSorter = sorters.find(([value]) => value === sorter);

  if (q) return null;

  return (
    <Menu>
      <Tooltip label="Sort by">
        <MenuButton
          flex="none"
          size="sm"
          variant="outline"
          as={Button}
          rightIcon={<Icon as={MdArrowDropDown} />}
        >
          {activeSorter?.[1]}
        </MenuButton>
      </Tooltip>
      <MenuList>
        <MenuOptionGroup
          onChange={(e) => setSorter(e as string)}
          value={sorter}
          title="Sort by"
          type="radio"
        >
          {sorters.map(([value, label]) => (
            <MenuItemOption value={value} key={value}>
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

import { RefObject, useState } from 'react';

import {
  AccordionButton,
  AccordionIcon,
  Box,
  Button,
  Flex,
} from '@chakra-ui/react';

import { AnswerEditor } from 'src/shared/components/AnswerEditor';
import { EditorButtons } from 'src/shared/components/Editor/Buttons/EditorButtons';
import { useSharedEditor } from 'src/shared/components/Editor/useSharedEditor';
import { QuestionEditTags } from 'src/shared/components/Question/EditTags/QuestionEditTags';
import { useSaver } from 'src/shared/components/Question/hooks/Question.hooks.useSaver';
import { QuestionContext } from 'src/shared/components/Question/QuestionContext';
import { TitleEditor } from 'src/shared/components/TitleEditor';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

export function QuestionEdit({
  setEditMode,
  buttonRef,
}: {
  setEditMode: (editMode: boolean) => void;
  buttonRef: RefObject<HTMLButtonElement>;
}) {
  const question = useRequiredContext(QuestionContext);
  const { editor } = useSharedEditor({
    editable: true,
    content: question.answer_json
      ? JSON.parse(question.answer_json)
      : undefined,
    autofocus: true,
  });
  const [title, setTitle] = useState(question?.title || '');
  const [tags, setTags] = useState(question.tags || []);
  const saver = useSaver(question, title, editor, tags, () =>
    setEditMode(false),
  );

  return (
    <Box px={4} py={2}>
      <Flex position="relative">
        <AccordionButton
          as={Button}
          ref={buttonRef}
          variant="link"
          flex="none"
          width="32px"
          height="32px"
          size="sm"
          onClick={() => setEditMode(false)}
          position="absolute"
          left={0}
          top={0}
          display="none"
        >
          <AccordionIcon color="black" />
        </AccordionButton>
        <TitleEditor title={title} setTitle={setTitle} pb={2} pt={2} />
      </Flex>
      <AnswerEditor mt={1} editor={editor} />
      <Flex mt={2} py={2}>
        {editor && <EditorButtons editor={editor} />}
        <Button
          isDisabled={!question}
          variant="link"
          colorScheme="black"
          ml="auto"
          size="sm"
          mr={4}
          onClick={() => setEditMode(false)}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          isDisabled={!question}
          colorScheme="blue"
          isLoading={saver.isSubmitting}
          onClick={saver.submit}
        >
          Save & Verify
        </Button>
      </Flex>
      <QuestionEditTags tags={tags} setTags={setTags} />
    </Box>
  );
}

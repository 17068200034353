import { Box, Button, Heading, Text } from '@chakra-ui/react';

import { UserField } from 'src/shared/components/UserField/UserField';
import { useDirectoryUsers } from 'src/shared/hooks/useDirectory';

export interface SettingsExpertsProps {
  channelId: string;
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
}

export function SettingsExperts({
  channelId,
  selectedIds,
  setSelectedIds,
}: SettingsExpertsProps) {
  const users = useDirectoryUsers();
  const channelExperts = users
    .filter(
      (user) =>
        !user.is_slack_deleted && user.expert_channels.includes(channelId),
    )
    .map((user) => user.id);

  return (
    <Box mt={8}>
      <Heading size="xs">Channel Experts</Heading>
      <Text fontSize="xs" color="action.600">
        Experts help keeping this channel up-to-date.
        <Button
          ml={1}
          href="#"
          as="a"
          variant="link"
          fontWeight="normal"
          fontSize="xs"
          color="blue"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Button>
      </Text>
      <Box mt={2} pb={8}>
        <UserField
          placeholder="+Invite experts"
          excludeDeleted
          excludedIds={channelExperts}
          forcedIds={channelExperts}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      </Box>
    </Box>
  );
}

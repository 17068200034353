import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { TbX } from 'react-icons/tb';

import {
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

export function QuestionEditTags({
  tags,
  setTags,
}: {
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [newTag, setNewTag] = useState('');

  const addTag = () => {
    if (!newTag.trim()) return;
    setTags((prev) => [...prev, newTag]);
    setNewTag('');
    inputRef.current?.focus();
  };

  return (
    <>
      <Heading fontSize="xs" mt={5} color="grey.2">
        Tags
      </Heading>
      <Flex flexWrap="wrap" mt={1} alignItems="center">
        {tags.map((tag) => (
          <Button
            key={tag}
            size="xs"
            fontWeight="medium"
            mt={2}
            mb={2}
            mr={2}
            onClick={() => setTags((prev) => prev.filter((t) => t !== tag))}
            rightIcon={<Icon as={TbX} />}
          >
            #{tag}
          </Button>
        ))}
        <InputGroup size="xs" w="160px">
          <Input
            ref={inputRef}
            pr={10}
            value={newTag}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && newTag) {
                addTag();
              }
            }}
            onChange={(e) => {
              // Only allow lowercase characters, dashes, numbers and underscores
              // Ensure that it starts with a letter or a number and no double dashes or underscores are allowed
              setNewTag(
                e.target.value
                  .replace(/[^a-z0-9-]/g, '')
                  .replace(/-+/g, '-')
                  .replace(/^-/, ''),
              );
            }}
            size="xs"
            placeholder="Add tag"
          />
          {newTag && (
            <InputRightElement width="50px">
              <Button size="xs" variant="link" onClick={addTag}>
                Add
              </Button>
            </InputRightElement>
          )}
        </InputGroup>
      </Flex>
    </>
  );
}

import { ComponentPropsWithoutRef } from 'react';

export function Logo({
  color = '#0A0A0A',
  ...svgProps
}: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="150"
      height="24"
      viewBox="0 0 150 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M33.2975 13.4548C33.2975 15.1056 32.4424 16.091 31.0102 16.091C29.7521 16.091 28.8389 15.1779 28.8389 13.9199V7.36768H25.6211V14.2222C25.6211 17.1258 27.4128 19.0765 30.0797 19.0765C31.885 19.0765 33.0169 18.0326 33.2975 17.7413V18.8438H36.5155V7.36768H33.2975V13.4548H33.2975Z"
        fill={color}
      />
      <path
        d="M43.6933 7.13525C40.4011 7.13525 37.7227 9.8137 37.7227 13.1059C37.7227 16.3983 40.4534 19.0768 43.8097 19.0768C46.9316 19.0768 48.7062 17.1062 48.7237 17.0861L48.745 17.0621L47.097 14.943L47.0658 14.9031L47.0353 14.9436C47.025 14.9574 45.9778 16.3239 44.0424 16.3239C42.1887 16.3239 41.2852 15.1861 41.066 14.1916H49.3065L49.3139 14.1623C49.3151 14.1576 49.4315 13.6829 49.4315 12.8734C49.4315 9.60224 46.9646 7.13526 43.6933 7.13526L43.6933 7.13525ZM41.0707 11.9041C41.4643 10.659 42.4648 9.8881 43.6933 9.8881C44.9582 9.8881 45.9391 10.6777 46.2046 11.9041H41.0707Z"
        fill={color}
      />
      <path
        d="M55.6253 11.965C54.6344 11.6459 53.8518 11.3937 53.8518 10.7797C53.8518 10.3502 54.2764 9.88805 55.2088 9.88805C56.3381 9.88805 57.0319 10.7939 57.0387 10.803L57.0675 10.8413L58.7272 8.94431L58.7477 8.92084L58.7293 8.89585C58.7161 8.87786 58.3979 8.44906 57.7454 8.0142C57.1441 7.61323 56.1351 7.13521 54.7435 7.13521C52.1159 7.13521 50.2849 9.05601 50.2849 10.7797C50.2849 13.0506 52.1598 13.6608 53.6662 14.1509C54.6603 14.4744 55.5189 14.7539 55.5189 15.4322C55.5189 16.0634 54.9682 16.4401 54.0456 16.4401C52.5715 16.4401 51.6436 15.1893 51.6344 15.1766L51.6077 15.1401L49.8086 16.9393L49.8262 16.9658C49.8403 16.9868 51.2708 19.0767 54.5108 19.0767C57.6711 19.0767 59.0858 17.2462 59.0858 15.4322C59.0858 13.0795 57.1671 12.4616 55.6253 11.965H55.6253Z"
        fill={color}
      />
      <path
        d="M66.0247 16.2079C65.2638 16.2079 64.6679 15.6629 64.6679 14.9671V10.4699H66.9942V7.60084H64.6679V3.99512H61.7988V6.12758C61.7988 6.9812 61.277 7.60084 60.5582 7.60084H59.4727V10.4699H61.4499V15.0836C61.4499 17.7334 62.6761 19.077 65.0944 19.077C66.251 19.077 67.1921 18.7288 67.2015 18.7254L67.2267 18.716V15.9514L67.1705 15.9794C67.1659 15.9817 66.7043 16.2079 66.0247 16.2079V16.2079Z"
        fill={color}
      />
      <path
        d="M72.2297 7.36768H69.0117V18.8438H72.2297V7.36768Z"
        fill={color}
      />
      <path
        d="M70.5609 2.3667C69.4941 2.3667 68.7773 3.08345 68.7773 4.15025C68.7773 5.21686 69.4941 5.93361 70.5609 5.93361C71.715 5.93361 72.4607 5.23352 72.4607 4.15025C72.4607 3.06679 71.715 2.3667 70.5609 2.3667Z"
        fill={color}
      />
      <path
        d="M79.5207 7.13525C76.2781 7.13525 73.4336 9.92521 73.4336 13.1059C73.4336 16.2866 76.2781 19.0768 79.5207 19.0768C82.7633 19.0768 85.6076 16.2866 85.6076 13.1059C85.6076 9.92521 82.7632 7.13525 79.5207 7.13525ZM79.5207 16.0912C77.9771 16.0912 76.768 14.78 76.768 13.1059C76.768 11.432 77.9771 10.1208 79.5207 10.1208C81.0643 10.1208 82.2733 11.432 82.2733 13.1059C82.2733 14.78 81.0643 16.0912 79.5207 16.0912Z"
        fill={color}
      />
      <path
        d="M93.1272 7.13526C91.3241 7.13526 90.193 8.34802 89.9094 8.69087V7.36792H86.6914V18.8441H89.9094V12.8734C89.9094 11.2226 90.7644 10.237 92.1967 10.237C93.475 10.237 94.3678 11.1777 94.3678 12.5245V18.8441H97.5858V12.1754C97.5858 10.6529 97.1499 9.37184 96.3252 8.47088C95.5255 7.59719 94.4197 7.13525 93.1272 7.13525V7.13526Z"
        fill={color}
      />
      <path
        d="M114.03 10.1922C114.36 9.97813 115.846 8.89959 115.846 6.82526C115.846 5.72325 115.322 4.62635 114.408 3.8157C113.439 2.9562 112.118 2.48291 110.689 2.48291H103.672V18.8442H111.085C112.658 18.8442 114.094 18.3283 115.126 17.3918C116.115 16.495 116.66 15.3034 116.66 14.0365C116.66 11.5048 114.452 10.3835 114.03 10.1922V10.1922ZM107.239 5.46843H110.457C111.581 5.46843 112.279 6.12194 112.279 7.17417C112.279 8.22639 111.581 8.88009 110.457 8.88009H107.239V5.46843ZM110.922 15.8587H107.239V11.7492H110.922C112.16 11.7492 113.093 12.6325 113.093 13.804C113.093 14.9753 112.16 15.8587 110.922 15.8587Z"
        fill={color}
      />
      <path
        d="M122.554 7.13525C119.544 7.13525 118.001 9.33984 117.986 9.36199L117.968 9.38869L119.768 11.1887L119.794 11.151C119.802 11.1395 120.619 10.0043 122.321 10.0043C123.486 10.0043 124.243 10.6145 124.26 11.5617L120.686 12.207C118.554 12.586 117.281 13.9222 117.281 15.7811C117.281 17.6599 118.948 19.0768 121.158 19.0768C122.864 19.0768 123.981 18.0338 124.26 17.7419V18.8441H127.478V11.7103C127.478 9.05928 125.407 7.13525 122.554 7.13525ZM124.26 13.9202C124.26 15.4746 123.383 16.4401 121.973 16.4401C121.022 16.4401 120.499 16.0409 120.499 15.316C120.499 14.6536 120.942 14.2126 121.747 14.0744L124.26 13.6176V13.9202V13.9202Z"
        fill={color}
      />
      <path
        d="M134.137 11.9651C133.146 11.6459 132.363 11.3937 132.363 10.7798C132.363 10.3502 132.788 9.8881 133.72 9.8881C134.85 9.8881 135.544 10.794 135.55 10.8031L135.579 10.8413L137.239 8.94436L137.259 8.92088L137.241 8.8959C137.228 8.87791 136.91 8.44911 136.257 8.01425C135.656 7.61328 134.647 7.13525 133.255 7.13525C130.628 7.13525 128.797 9.05606 128.797 10.7798C128.797 13.0506 130.671 13.6608 132.178 14.1509C133.172 14.4745 134.031 14.7539 134.031 15.4322C134.031 16.0634 133.48 16.4401 132.557 16.4401C131.083 16.4401 130.155 15.1893 130.146 15.1767L130.119 15.1401L128.32 16.9394L128.338 16.9659C128.352 16.9869 129.782 19.0768 133.022 19.0768C136.183 19.0768 137.597 17.2463 137.597 15.4322C137.597 13.0796 135.679 12.4617 134.137 11.9651Z"
        fill={color}
      />
      <path
        d="M143.959 7.13525C140.667 7.13525 137.988 9.8137 137.988 13.1059C137.988 16.3983 140.719 19.0768 144.075 19.0768C147.197 19.0768 148.972 17.1062 148.99 17.0863L149.011 17.062L147.363 14.943L147.331 14.9031L147.301 14.9436C147.291 14.9574 146.242 16.3239 144.308 16.3239C142.454 16.3239 141.551 15.1861 141.332 14.1916H149.572L149.58 14.1623C149.581 14.1576 149.697 13.6829 149.697 12.8734C149.697 9.60223 147.23 7.13525 143.959 7.13525ZM141.336 11.9041C141.73 10.659 142.73 9.8881 143.959 9.8881C145.224 9.8881 146.205 10.6777 146.47 11.9041H141.336Z"
        fill={color}
      />
      <path
        d="M12.0121 0C8.84637 0 5.87486 1.16543 3.645 3.2818C1.29445 5.51232 0 8.60859 0 12C0 15.3914 1.29445 18.4877 3.645 20.7182C5.87486 22.8346 8.84637 24 12.0121 24C13.9497 24 15.8657 23.5487 17.5529 22.695L17.7193 22.6108L16.1499 19.9651L16.006 20.0338C14.7805 20.6192 13.3994 20.9285 12.0121 20.9285C6.90399 20.9285 3.05187 17.0901 3.05187 12C3.05187 6.90985 6.90399 3.07147 12.0121 3.07147C17.1063 3.07147 20.9479 6.90985 20.9479 12C20.9479 13.4668 20.6412 14.8361 20.0362 16.0699L19.9658 16.2138L22.5875 17.7916L22.6728 17.6255C23.5533 15.9098 23.9998 14.017 23.9998 12C23.9998 8.61067 22.7071 5.51516 20.3598 3.2835C18.1327 1.16619 15.1681 0 12.0121 0Z"
        fill={color}
      />
      <path
        d="M19.1831 11.9998C19.1831 7.90353 16.0981 4.81445 12.0072 4.81445C7.90555 4.81445 4.8125 7.90353 4.8125 11.9998C4.8125 16.096 7.90555 19.1851 12.0072 19.1851C13.4275 19.1851 14.7887 18.7941 15.9543 18.0531L20.0047 22.9048L22.9026 20.0067L18.0597 15.9635C18.7952 14.8185 19.1831 13.4516 19.1831 11.9998L19.1831 11.9998ZM12.0072 16.1331C9.9566 16.1331 7.88084 14.7134 7.88084 11.9998C7.88084 9.2861 9.9566 7.86642 12.0072 7.86642C14.0484 7.86642 16.1149 9.2861 16.1149 11.9998C16.1149 14.7134 14.0484 16.1331 12.0072 16.1331Z"
        fill={color}
      />
    </svg>
  );
}

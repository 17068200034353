import { createRoot } from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';

import { App } from 'src/App/App';
import { chakraTheme } from 'src/chakra-theme/chakra.themeExtend';
import { DuplicatePage } from 'src/Duplicate/DuplicatePage';
import { EmbeddingTesterPage } from 'src/EmbeddingTester/EmbeddingTesterPage';
import { IntegrationPage } from 'src/Integration/IntegrationPage';
import { LoginPage } from 'src/Login/LoginPage';
import { QuestionPage } from 'src/Question/QuestionPage';
import { QuestionPageUnauthorized } from 'src/Question/QuestionPageUnauthorized';
import { SearchPage } from 'src/Search/SearchPage';
import { SettingsPage } from 'src/Settings/SettingsPage';
import { DirectoryProvider } from 'src/shared/components/Directory/Directory';
import { BroadcasterProvider } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { handleToken } from 'src/shared/util/util.handleToken';
import { TesterExtractPage } from 'src/Tester/Extract/TesterExtractPage';

handleToken();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <ChakraProvider theme={chakraTheme}>
      <BroadcasterProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/tester"
            element={
              <DirectoryProvider>
                <EmbeddingTesterPage />
              </DirectoryProvider>
            }
          />
          <Route path="tester/extract" element={<TesterExtractPage />} />
          <Route path="/q/:id/:token" element={<QuestionPageUnauthorized />} />
          <Route
            path="*"
            element={
              <DirectoryProvider>
                <App>
                  <Routes>
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="/duplicates" element={<DuplicatePage />} />
                    <Route path="/questions" element={<SearchPage />} />
                    <Route path="/q/:id" element={<QuestionPage />} />
                    <Route
                      path="*"
                      element={<Navigate to="/questions" replace />}
                    />
                    <Route path="/integration" element={<IntegrationPage />} />
                  </Routes>
                </App>
              </DirectoryProvider>
            }
          />
        </Routes>
      </BroadcasterProvider>
    </ChakraProvider>
  </BrowserRouter>,
);

import { forwardRef } from 'react';
import reactTextareaAutosize from 'react-textarea-autosize';

import { PropsOf, Textarea } from '@chakra-ui/react';

export interface TitleEditorProps {
  title: string;
  setTitle: (title: string) => void;
}

export const TitleEditor = forwardRef(function TitleEditor(
  {
    title,
    setTitle,
    ...textareaProps
  }: TitleEditorProps & PropsOf<typeof Textarea>,
  ref,
) {
  return (
    <Textarea
      ref={ref}
      as={reactTextareaAutosize}
      fontSize="sm"
      placeholder={`Question...`}
      resize="none"
      size="md"
      maxRows={10}
      variant="flushed"
      minH="auto"
      borderBottom="1px solid #dddddd"
      overflow="hidden"
      fontWeight="bold"
      position="relative"
      onChange={(e) => setTitle(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      value={title}
      {...textareaProps}
    />
  );
});

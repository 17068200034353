import { LuCopy } from 'react-icons/lu';

import {
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function AddToChannel() {
  const team = useDirectoryTeam();

  return (
    <Center display="flex" flexDirection="column">
      <Heading size="sm" mt={10}>
        Welcome to Question Base &nbsp;👋
      </Heading>
      <Text maxW="420px" mt={5}>
        To make most out of your automation, schedule a free personal onboarding
        call with Yana from our team.
      </Text>
      <Flex mt={10}>
        <Button
          as="a"
          href={`slack://open?team=${team.id}`}
          target="_blank"
          colorScheme="action"
          variant="solid"
          mr={3}
        >
          Open Slack
        </Button>
        <Button
          as="a"
          href={`https://cal.com/team/questionbase/product-demo`}
          target="_blank"
          colorScheme="action"
          variant="outline"
        >
          cal.com/team/questionbase/product-demo
        </Button>
      </Flex>

      <Text mt={10} fontWeight={500}>
        Next step:
      </Text>
      <Flex alignItems="center">
        <Text fontWeight={500}>In Slack →</Text>
        <Flex
          alignItems="center"
          pl={4}
          ml={4}
          pr={2}
          py={1}
          border="1px solid"
          borderColor="action.200"
          borderRadius={10}
        >
          <Text fontWeight={500}>/invite @questionbase</Text>
          {/** Copy url to clipboard button */}
          <Tooltip label="Copy command to clipboard" placement="top">
            <IconButton
              size="sm"
              ml={2}
              colorScheme="action"
              variant="ghost"
              onClick={() =>
                navigator.clipboard.writeText(`/invite @questionbase`)
              }
              icon={<Icon as={LuCopy} />}
              aria-label="Copy command to clipboard"
            />
          </Tooltip>
        </Flex>
        <Text fontWeight={500} ml={4}>
          to a #channel
        </Text>
      </Flex>
    </Center>
  );
}

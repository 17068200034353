import { useEffect, useRef, useState } from 'react';
import { IoWarningOutline } from 'react-icons/io5';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { QuestionDuplicateCheckEndpoint } from '@core/types/types.endpoint.question';

import { DuplicateQuestion } from 'src/shared/components/DuplicateQuestionButton/DuplicateQuestion';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';

export interface DuplicateQuestionButtonProps {
  title: string;
  inputRef: React.RefObject<HTMLTextAreaElement>;
}

export function DuplicateQuestionButton({
  title,
  inputRef,
}: DuplicateQuestionButtonProps) {
  const initialRef = useRef(null);
  const [query, setQuery] = useState(title);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Determine if the input has focus
  useEffect(() => {
    if (inputRef.current !== document.activeElement && query !== title) {
      setQuery(title);
    }
  });

  const { data: duplicates, loading } = useApi<QuestionDuplicateCheckEndpoint>(
    '/question/duplicateCheck',
    { query },
    !!query,
  );

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onOpen();
        }}
        colorScheme="error"
        isLoading={loading}
        loadingText="Checking for duplicates..."
        aria-label="Potential duplicate found"
        leftIcon={<Icon as={IoWarningOutline} h={5} w={5} />}
        hidden={!loading && !duplicates?.length}
      >
        Potential duplicate
      </Button>

      {!!duplicates?.length && (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={initialRef}
          size="sm"
        >
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Potential Duplicates</DrawerHeader>
            <DrawerBody>
              {duplicates?.map((q) => (
                <DuplicateQuestion key={q.id} question={q} />
              ))}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

import { useEffect, useRef, useState } from 'react';

import { SearchFilter } from '@core/types/types.common';
import { EndpointError } from '@core/types/types.endpoint';
import { QuestionListEndpoint } from '@core/types/types.endpoint.question';

import { DEFAULT_LIMIT } from 'src/Search/const/Search.const.DEFAULT_LIMIT';
import { useBuster } from 'src/shared/hooks/useBuster';
import { callApi } from 'src/shared/util/util.callApi';

export interface QuestionFilter {
  filter?: SearchFilter;
  query?: string;
  sorter?: string;
  condition?: boolean;
  skip?: number;
  limit?: number;
  minScore?: number;
}

export function useESQuestionList(searchQuery: QuestionFilter) {
  const buster = useBuster('bust');
  const [result, setResult] = useState<QuestionListEndpoint['response'] | null>(
    null,
  );
  const [error, setError] = useState<EndpointError | null>(null);
  const [loading, setLoading] = useState(true);

  const uniqueRef = useRef('');

  const {
    condition,
    skip,
    sorter,
    filter = {},
    query,
    limit = DEFAULT_LIMIT,
    minScore,
  } = searchQuery;

  const { verified_by, tags } = filter;

  useEffect(() => {
    const uniqueId = `${verified_by}-${sorter}-${query}-${buster}-${limit}-${skip}-${minScore}-${tags?.join(
      '-',
    )}`;

    if (condition === false || uniqueRef.current === uniqueId) return;

    uniqueRef.current = uniqueId;
    setLoading(() => true);

    const isId = !!query?.match(/^Q[A-Z0-9]{5}$/);

    callApi<QuestionListEndpoint>('/question/list', {
      skip,
      sorter,
      limit,
      filter: {
        verified_by,
        tags,
        id: isId ? query : undefined,
      },
      query: isId ? undefined : query,
      minScore,
    }).then(([err, res]) => {
      if (uniqueRef.current === uniqueId) {
        setResult(res);
        setError(err);
        setLoading(false);
      }
    });
  }, [
    loading,
    condition,
    skip,
    sorter,
    verified_by,
    limit,
    query,
    buster,
    tags,
    minScore,
  ]);

  return {
    loading,
    error,
    result,
  };
}

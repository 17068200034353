import { DBTeam } from '@core/types/types.db';

export type ChannelSettings = {
  case_open: 'messages' | 'questions' | 'manual';
};

export function channelSettings(team: DBTeam, channelId: string) {
  // Prep for pr channel settings...

  return team.channel_settings[channelId] ?? {};
}

import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { ESEmbeddingEndpoint } from '@core/types/types.endpoint.es';

import { EmbeddingAddRow } from 'src/EmbeddingTester/EmbeddingAddRow';
import {
  EmbeddingTestCase,
  TestCase,
} from 'src/EmbeddingTester/EmbeddingTestCase';
import { SearchPage } from 'src/Search/SearchPage';
import { callApi } from 'src/shared/util/util.callApi';

export function EmbeddingTesterPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fetchQ, setFetchQ] = useState<number[]>([]);
  const [bulkInput, setBulkInput] = useState('');
  const [testCases, setTestCases] = useState<TestCase[]>(
    JSON.parse(localStorage.getItem('testcases') || '[]'),
  );

  const nextI = fetchQ[0];
  const nextMsg = testCases[nextI]?.message;
  useEffect(() => {
    if (nextMsg) {
      callApi<ESEmbeddingEndpoint>('/es/embedding', {
        input: nextMsg,
      }).then(([err, res]) => {
        testCases[nextI].actual = res?.actual;
        testCases[nextI].explanation = res?.reasoning;
        testCases[nextI].wants_information = res?.asking_question;
        testCases[nextI].wantsInfoReasoning = res?.wantsInfoReasoning;

        testCases[nextI].error = err ? 'error' : undefined;
        setFetchQ((old) => old.slice(1));
      });
    }
  }, [nextI, nextMsg]);

  const onAdd = (
    message: string,
    expected: string,
    expectedWantsInfo: string,
    explanation?: string,
    wants_information?: boolean,
    wantsInfoReasoning?: string,
  ) => {
    setTestCases((old) => [
      ...old,
      {
        message,
        expected,
        expectedWantsInfo,
        explanation,
        wants_information,
        wantsInfoReasoning,
      },
    ]);
  };

  const handleBulkAdd = () => {
    const messages = bulkInput.split('\n').filter(Boolean);
    messages.forEach((message) => {
      onAdd(message, 'none', 'true'); // Adjust default values as needed
    });
    setBulkInput('');
    onClose();
  };

  useEffect(() => {
    localStorage.setItem('testcases', JSON.stringify(testCases));
  }, [testCases]);

  const total = testCases.filter(({ actual }) => !!actual).length;
  const all_passed = testCases.filter(
    ({ actual, expected, expectedWantsInfo, wants_information }) =>
      actual === expected &&
      expectedWantsInfo === wants_information?.toString(),
  ).length;
  const asking_question_passed = testCases.filter(
    ({ expectedWantsInfo, wants_information }) =>
      expectedWantsInfo === wants_information?.toString(),
  ).length;
  const autoresponse_passed = testCases.filter(
    ({ actual, expected }) => actual === expected,
  ).length;

  return (
    <Flex
      w="100%"
      margin="50px auto"
      maxW="1600px"
      p={5}
      justifyContent="space-between"
    >
      <Box w="48%" flex="none">
        <Flex mt={5} ml={6} alignItems="center">
          <Button
            colorScheme="messenger"
            onClick={() => {
              setTestCases((old) =>
                old.map((o) => ({
                  ...o,
                  actual: undefined,
                  error: undefined,
                })),
              );
              setFetchQ((old) => [
                ...old,
                ...Object.keys(testCases).map((s) => parseInt(s)),
              ]);
            }}
          >
            Run all
          </Button>
          <Button colorScheme="teal" ml={3} onClick={onOpen}>
            Add Bulk
          </Button>
          {!!total && (
            <Flex alignContent={'center'}>
              <Text fontWeight="bold" ml={3}>
                overall results:
              </Text>
              {((all_passed / total) * 100).toFixed(3)}% ({all_passed}/{total})
              <Text fontWeight="bold" ml={3}>
                asking question:
              </Text>
              {((asking_question_passed / total) * 100).toFixed(3)}% (
              {asking_question_passed}/{total})
              <Text fontWeight="bold" ml={3}>
                auto response:
              </Text>
              {((autoresponse_passed / total) * 100).toFixed(3)}% (
              {autoresponse_passed}/{total})
            </Flex>
          )}
        </Flex>
        <Flex alignItems="center" mt={3}>
          <Text fontWeight="bold" ml={8} w="100%">
            Message
          </Text>
          <Text fontWeight="bold" ml={3} w="70px" flex="none">
            Expected response
          </Text>
          <Text fontWeight="bold" ml={3} w="70px" flex="none">
            Expected Wants Info
          </Text>
          <Text fontWeight="bold" ml={3} w="70px" flex="none">
            Returned
          </Text>
          <Text fontWeight="bold" ml={3} w="70px" flex="none">
            Wants Info?
          </Text>
          <Text fontWeight="bold" ml={3} w="45px" flex="none">
            Logic
          </Text>
        </Flex>

        {testCases.map((r, i) => (
          <EmbeddingTestCase
            key={i}
            isFetching={nextI === i}
            state={r}
            onReRun={() => setFetchQ((old) => [...old, i])}
            setState={(newState) =>
              setTestCases((oldResults) =>
                oldResults.map((existingState, j) =>
                  i === j ? newState : existingState,
                ),
              )
            }
          />
        ))}
        <EmbeddingAddRow onAdd={onAdd} />
      </Box>
      <Box w="48%" flex="none">
        <SearchPage />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Bulk Messages</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Paste your messages here, one per line"
              value={bulkInput}
              onChange={(e) => setBulkInput(e.target.value)}
              rows={10}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleBulkAdd}>
              Add Messages
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

import { useContext } from 'react';

export const useRequiredContext = <T>(Context: React.Context<T>) => {
  const context = useContext(Context);

  if (typeof context === 'undefined' || context === null) {
    throw Error(
      `Context not found: ${Context.displayName ?? 'displayName not set :('}`,
    );
  }

  return context as NonNullable<T>;
};
